/** @format */

import socketIOClient from 'socket.io-client';

const {MYCORP_WORKFLOW_ENDPOINTS} = require('tools/Constants');
const uuidv1 = require('uuid/v1');

class MyCorpDocApprovalSocket {
    _socket = null;
    _socketInitialized = false;

    async init(token, account, system, module, onConnect) {
        this._socket = socketIOClient(MYCORP_WORKFLOW_ENDPOINTS, {
            path: '/workflow',
            rememberTransport: false,
            transports: ['websocket'],
            query: {
                token,
                account,
                system,
                module,
            },
        });

        this._socketInitialized = false;
        this._socket.on('socketInitialized', (response) => {
            if (!this._socketInitialized) {
                onConnect();
                // Prevent this from being re-initialised on network disconnection scenario/
                // This is not needed to be implemented in the other pages, as this is a workaround/solution for the InstaTask/InstaAPV listing.
                this._socketInitialized = true;
            }
        });
    }

    async stop() {
        if (this._socket) this._socket.disconnect();
    }

    updateActiveDocsListener = (filter) => {
        this._socket.emit('updateActiveDocsListener', filter);
    };

    subscribeActiveDocs = (callback) => {
        this._socket.on('activeDocs', (response) => {
            callback && callback(response);
        });
    };

    updateArchiveDocsListener = (filter) => {
        this._socket.emit('updateArchiveDocsListener', filter);
    };

    subscribeArchiveDocs = (callback) => {
        this._socket.on('archiveDocs', (response) => {
            callback && callback(response);
        });
    };

    updateCommentsListener = (filter) => {
        this._socket.emit('updateCommentsListener', filter);
    };

    subscribeComments = (callback) => {
        this._socket.on('comments', (response) => {
            callback && callback(response);
        });
    };

    putCommentOnTask = ({taskId, message, commentAuthor, subject, assignee, originator}) => {
        const {account} = this._socket.io.opts.query;
        const correlationId = uuidv1();

        this._socket.emit('putCommentOnTask', {
            correlationId,
            taskId,
            message,
            account,
            subject,
            commentAuthor,
            assignee,
            originator,
        });
    };

    cloneCommentOnTask = ({taskId, newTaskId}) => {
        const {account} = this._socket.io.opts.query;
        const correlationId = uuidv1();

        this._socket.emit('cloneCommentOnTask', {
            correlationId,
            taskId,
            account,
            newTaskId,
        });
    };

    submitNewDoc = ({
        subject,
        remarks,
        client,
        type,
        assignee,
        priority,
        dueDate,
        background,
        request,
        doc,
        taskId,
        account,
    }) => {
        const correlationId = uuidv1();
        return new Promise(async (resolve, reject) => {
            this._socket.emit('submitNewDoc', {
                correlationId,
                assignee,
                // watchers: type === 'approval' ? 'larry@cyder.com.sg~fernando@cyder.com.sg~yin@cyder.com.sg' : '-', // Updated by Fernando, watchers are not needed
                watchers: '-', // Updated by Fernando, watchers are not needed
                subject,
                remarks,
                account,
                client,
                doc,
                type,
                priority,
                dueDate,
                background,
                request,
                taskId,
            });

            this._socket.on(correlationId, (response) => {
                if (response) {
                    resolve(response || {});
                    return;
                }

                reject(response);
            });
        });
    };

    completeTask = ({taskId, approved, message, email}) => {
        const {account} = this._socket.io.opts.query;
        const correlationId = uuidv1();

        this._socket.emit('completeTask', {
            correlationId,
            taskId,
            approved,
            message,
            email,
            account,
        });
    };

    cancelTask = ({taskId, email}) => {
        const {account} = this._socket.io.opts.query;
        const correlationId = uuidv1();
        this._socket.emit('completeTask', {
            correlationId,
            taskId,
            approved: 'FALSE',
            message: '',
            email,
            account,
        });
    };

    acceptTask = ({taskId, approved, message, email}) => {
        const {account} = this._socket.io.opts.query;
        const correlationId = uuidv1();

        this._socket.emit('acceptTask', {
            correlationId,
            taskId,
            approved,
            message,
            email,
            account,
        });
    };

    reAcceptTask = ({taskId, approved, message, dueDate, email}) => {
        const {account} = this._socket.io.opts.query;
        const correlationId = uuidv1();

        this._socket.emit('reAcceptTask', {
            correlationId,
            taskId,
            approved,
            message,
            dueDate,
            email,
            account,
        });
    };

    closeTask = ({taskId, email}) => {
        const {account} = this._socket.io.opts.query;
        const correlationId = uuidv1();

        this._socket.emit('closeTask', {
            correlationId,
            taskId,
            email,
            account,
        });
    };

    reopenTask = ({taskId, email}) => {
        const {account} = this._socket.io.opts.query;
        const correlationId = uuidv1();

        this._socket.emit('reopenTask', {
            correlationId,
            taskId,
            email,
            account,
        });
    };

    updateReadCount = ({taskId, email, readCount}) => {
        const {account} = this._socket.io.opts.query;
        const correlationId = uuidv1();

        this._socket.emit('updateReadCount', {
            correlationId,
            account,
            readCount,
            email,
            taskId,
        });
    };

    getReadCount = ({taskId, email, account}) => {
        const correlationId = uuidv1();
        return new Promise(async (resolve, reject) => {
            this._socket.emit('getReadCount', {
                correlationId,
                taskId,
                email,
                account,
            });

            this._socket.on(correlationId, (response) => {
                if (response && response.dataset) {
                    resolve(response.dataset[0] || {});
                    return;
                }

                reject(response);
            });
        });
    };
}

export const myCorpDocApprovalSocket = new MyCorpDocApprovalSocket();
