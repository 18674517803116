/** @format */
import styled from 'styled-components';
import {Grid, Typography, IconButton} from '@material-ui/core';

export const FunctionalButton = styled(IconButton)`
    background: ${(props) => props.theme.palette[props.color].main};
    color: ${(props) => props.theme.palette[props.color].contrastText};
    width: 56px !important;
    height: 56px !important;
    border-radius: ${(props) =>
        props.length > 1 &&
        ((props.index === 0 && '20% 0% 0% 20%') || (props.index === props.length - 1 && '0% 20% 20% 0%') || 'unset')};

    :hover {
        background: ${(props) => props.theme.palette.primary.dark};
    }
`;

export const Divider = styled.span`
    width: 1px;
    display: inline-flex;
    background: ${(props) => props.theme.palette.primary.contrastText};
`;

export const TabsWrapper = styled.div`
    display: ${(props) => (props.show ? 'flex' : 'none')};
    justify-content: center;
    transition: all 0.3s ease-in-out;

    .container {
        position: fixed;
        height: 50px;
        display: flex;
        width: 50%;
        justify-content: center;
    }

    .container-functional {
        z-index: 9;
        bottom: 60px;

        ${(props) => props.theme.breakpoints.down('sm')} {
            bottom: 30px;
        }
    }

    .container-tabs {
        bottom: 25px;
        background: ${(props) => props.theme.palette.background.tertiary};
        box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 2px 6px 2px rgba(60, 64, 67, 0.15);
        border-radius: 10px;

        ${(props) => props.theme.breakpoints.down('md')} {
            width: 95%;
            bottom: 20px;
        }

        ${(props) => props.theme.breakpoints.down('sm')} {
            width: 100%;
            bottom: 0px;
            border-radius: 0px;
        }

        .tab-button {
            height: 100%;
            width: 100%;
            border-radius: 10px;
            border: 0px;
            text-transform: initial;

            h6 {
                color: ${(props) => props.theme.palette.primary.main};
                ${(props) => props.theme.breakpoints.down('sm')} {
                    display: none;
                }
            }

            svg {
                color: ${(props) => props.theme.palette.primary.main};
                margin: 0 10px;
            }

            :hover {
                border: 0px;
            }
        }
    }
`;
