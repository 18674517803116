/** @format */

import React, { Component } from 'react';
import { myCorpAuthSocket, myCorpAuthSocketWithInit } from 'sockets/Auth/Auth';

export const AuthContext = React.createContext();

export default class AuthSocketProvider extends Component {
    render() {
        return (
            <AuthContext.Provider
                value={{
                    myCorpAuthSocket,
                    myCorpAuthSocketWithInit
                }}
            >
                {this.props.children}
            </AuthContext.Provider>
        );
    }
}
