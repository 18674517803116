/** @format */

import MuiButton from './MuiButton';
import MuiIconButton from './MuiIconButton';
import MuiPaper from './MuiPaper';
import MuiTableCell from './MuiTableCell';
import MuiTableHead from './MuiTableHead';
import MuiTypography from './MuiTypography';
import MuiListItemIcon from './MuiListItemIcon';
import MuiCard from './MuiCard';
import MuiCardContent from './MuiCardContent';
import MuiMenuItem from './MuiMenuItem';
import MuiDialog from './MuiDialog';

export default (palette, darkMode) => ({
    MuiButton: MuiButton(palette, darkMode),
    MuiIconButton: MuiIconButton(palette),
    MuiPaper: MuiPaper(palette),
    MuiTableCell: MuiTableCell(palette),
    MuiTableHead: MuiTableHead(palette),
    MuiTypography: MuiTypography(palette),
    MuiListItemIcon: MuiListItemIcon(palette),
    MuiCard: MuiCard(palette),
    MuiMenuItem: MuiMenuItem(palette),
    MuiDialog: MuiDialog(palette),
    MuiCardContent: MuiCardContent(palette),
});
