/** @format */

import React, {Component, useState, useEffect} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import clsx from 'clsx';

import {Grid, Typography} from '@material-ui/core';
import styled from 'styled-components';

export const Link = (props) => {
    const {className, children} = props;

    return (
        <StyledLink {...props} className={clsx('link', className)}>
            <Typography variant="overline">{children}</Typography>
        </StyledLink>
    );
};

export const HyperLink = (props) => {
    const {className, children} = props;

    return (
        <a {...props} className={clsx('link', className)}>
            <Typography variant="overline">{children}</Typography>
        </a>
    );
};

const StyledLink = styled(RouterLink)`
    :hover {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        div,
        span {
            color: ${(props) => props.theme.palette.secondary.dark};
            transition: all 0.3s ease-in-out;
        }
    }
`;
