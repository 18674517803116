/** @format */

import React, {Component} from 'react';
import {myCorpAuthoriseSocket} from 'sockets/Authorise/Authorise';

export const AuthoriseContext = React.createContext();

export default class SocketProvider extends Component {
    render() {
        return (
            <AuthoriseContext.Provider
                value={{
                    myCorpAuthoriseSocket,
                }}
            >
                {this.props.children}
            </AuthoriseContext.Provider>
        );
    }
}
