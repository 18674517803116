/** @format */

import React from 'react';
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const Alert = (props) => {
    const {open, onClose, onPositive, onNegative, title, remarks, children} = props;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableBackdropClick
            disableEscapeKeyDown
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                {children || (
                    <DialogContentText id="alert-dialog-description">
                        <>{remarks && (typeof remarks === 'string' ? remarks : remarks())}</>
                    </DialogContentText>
                )}
            </DialogContent>
            <DialogActions>
                {onPositive && <PositiveButton onPositive={onPositive} />}
                {onNegative && <NegativeButton onNegative={onNegative} />}
            </DialogActions>
        </Dialog>
    );
};

const PositiveButton = (props) => {
    const {onPositive} = props;
    const isObject = typeof onPositive === 'object';
    if (!onPositive) return null;

    return (
        <Button
            color="primary"
            autoFocus
            onClick={isObject ? onPositive.onClick : onPositive}
            disabled={isObject ? onPositive.disabled : false}
            {...(isObject ? onPositive : {})}
        >
            {isObject ? onPositive.label : 'Ok'}
        </Button>
    );
};

const NegativeButton = (props) => {
    const {onNegative} = props;
    const isObject = typeof onNegative === 'object';
    if (!onNegative) return null;

    return (
        <Button
            color="primary"
            onClick={isObject ? onNegative.onClick : onNegative}
            disabled={isObject ? onNegative.disabled : false}
            {...(isObject ? onNegative : {})}
        >
            {isObject ? onNegative.label : 'Cancel'}
        </Button>
    );
};

export default Alert;
