/** @format */

import React from 'react';
import {DatePicker} from '@material-ui/pickers';
import styled from 'styled-components';
import moment from 'moment';

const Datepicker = (props) => {
    const {
        margin,
        label,
        mandatory,
        pattern = 'DD-MM-YYYY', // return format
        value,
        onChange,
        ...rest
    } = props;

    return (
        <Wrapper margin={margin}>
            <DatePicker
                autoOk
                allowKeyboardControl
                inputVariant="filled"
                format="DD/MM/YYYY" // display format
                label={
                    <>
                        {label} {!mandatory ? null : <Mandatory>*</Mandatory>}
                    </>
                }
                clearable
                value={value ? moment(value, pattern) : null}
                onChange={(value) => onChange(value.format(pattern))}
                {...rest}
            />
        </Wrapper>
    );
};

const Mandatory = styled.span`
    color: ${(props) => props.theme.palette.error.main};
`;

const Wrapper = styled.div`
    margin: ${(props) => (props.margin ? '15px 15px 15px 0px' : '15px 0px')};

    .MuiFilledInput-root {
        border-radius: 4px;
    }

    .MuiFilledInput-underline:before {
        border: unset;
    }

    .MuiFilledInput-underline:after {
        border-bottom: unset;
    }
`;

export default Datepicker;
