/** @format */

import socketIOClient from 'socket.io-client';
const {MYCORP_AUDIT_ENDPOINTS} = require('tools/Constants');

class MyCorpAuditSocket {
    _socket = null;

    async stop() {
        if (this._socket) this._socket.disconnect();
    }

    async init(token, onConnect) {
        this._socket = socketIOClient(MYCORP_AUDIT_ENDPOINTS, {
            path: '/audit',
            rememberTransport: false,
            transports: ['websocket'],
            query: {
                token,
            },
        });

        this._socket.on('socketInitialized', (response) => {
            onConnect();
        });

        this._socket.on('disconnect', (response) => {
            console.log('disconnect');
        });
    }

    updateAuditQueryListener = (filter) => {
        this._socket.emit('updateAuditQueryListener', filter);
    };

    subscribeAuditQuery = (callback) => {
        this._socket.on('auditQuery', (response) => {
            callback && callback(response);
        });
    };
}

const myCorpAuditSocket = new MyCorpAuditSocket();
export {myCorpAuditSocket};
