/**
 * Notify Socket
 *
 * @format
 */

import socketIOClient from 'socket.io-client';
const {MYCORP_NOTIFY_ENDPOINTS} = require('tools/Constants');
const uuidv1 = require('uuid/v1');

class NotifySocket {
    _socket = null;
    _socketInitialized = false;

    init(token, account, system, onConnect) {
        this._socket = socketIOClient(MYCORP_NOTIFY_ENDPOINTS, {
            path: '/notify',
            rememberTransport: false,
            transports: ['websocket'],
            query: {
                token,
                account,
                system,
            },
        });

        this._socketInitialized = false;
        this._socket.on('socketInitialized', (response) => {
            if (!this._socketInitialized) {
                onConnect();
                // Prevent this from being re-initialised on network disconnection scenario/
                // This is not needed to be implemented in the other pages, as this is a workaround/solution for the InstaTask/InstaAPV listing.
                this._socketInitialized = true;
            }
        });
    }

    async stop() {
        if (this._socket) this._socket.disconnect();
    }

    subscribeNotify = (callback) => {
        this._socket.on('notify', (response) => {
            callback && callback(response);
        });
    };
}

export const myCorpNotifySocketSocket = new NotifySocket();
