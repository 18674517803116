/** @format */

import React, {Suspense, useState, useContext, useEffect} from 'react';
import {Route, Redirect} from 'react-router-dom';
import PropTypes from 'prop-types';

import {AuthContext} from 'contexts/Auth';
import {Loader} from 'components';

import {LockModeProvider} from 'contexts/LockMode';

const RouteWithLayout = (props) => {
    const {computedMatch, location, layout: Layout, path, iframe} = props;
    const {params} = computedMatch || {};

    const isWeb = location.pathname.includes('web');

    return (
        <LockModeProvider {...props}>
            {isWeb ? (
                <Layout iframe={iframe}>
                    <WebRoute {...props} params={params}></WebRoute>
                </Layout>
            ) : (
                <MobileRoute {...props} params={params}></MobileRoute>
            )}
        </LockModeProvider>
    );
};

const MobileRoute = (props) => {
    const {params, label, description, component: Component, ...rest} = props;
    const authContext = useContext(AuthContext);

    const mobileLogin = async (token) => {
        try {
            // set account and user info for mobile pages
            const res = (await authContext.mobileLogin(token)) || {};
            if (res.email) await authContext.findUser(res.email);
        } catch (e) {
            console.log('e: ', e);
        }
    };

    useEffect(() => {
        const {token} = params;
        if (!token) return;
        setTimeout(() => mobileLogin(token), 2000);
    }, [params.token]);

    return (
        <Route
            {...rest}
            render={(matchProps) => {
                return (
                    <Suspense fallback={<Loader loading />}>
                        <Component {...Object.assign({}, matchProps, params)} />
                    </Suspense>
                );
            }}
        />
    );
};

const WebRoute = (props) => {
    const {
        location,
        publicPage,
        privatePage,
        params,
        label,
        description,
        component: Component,
        layout: Layout,
        ...rest
    } = props;
    const {isLogin} = useContext(AuthContext);

    // login and try to access public page
    if (isLogin && publicPage) {
        return (
            <Redirect
                to={{
                    pathname: '/web/dashboard/home',
                    state: {from: location.pathname},
                }}
            />
        );
    }

    // not login and try to access private page
    if (!isLogin && privatePage) {
        return (
            <Redirect
                to={{
                    pathname: '/web/login',
                    state: {from: location.pathname},
                }}
            />
        );
    }

    return (
        <Route
            {...rest}
            render={(matchProps) => {
                return (
                    <Suspense fallback={<Loader loading />}>
                        <Component {...Object.assign({}, {...matchProps, ...params})} />
                    </Suspense>
                );
            }}
        />
    );
};

RouteWithLayout.propTypes = {
    component: PropTypes.any.isRequired,
    layout: PropTypes.any.isRequired,
    path: PropTypes.string,
};

export default RouteWithLayout;
