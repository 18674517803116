/** @format */

import React, {createContext, Component} from 'react';
import {myCorpExpensesSocket} from 'sockets/Expenses/Expenses';

export const ExpensesContext = createContext({});

export default class ExpensesSocketProvider extends Component {
    render() {
        return (
            <ExpensesContext.Provider
                value={{
                    myCorpExpensesSocket,
                }}
            >
                {this.props.children}
            </ExpensesContext.Provider>
        );
    }
}
