/** @format */

import {useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import {getAccountDetails} from 'api/Auth';
import {AuthContext} from 'contexts/Auth';

const AccountCheck = (props) => {
    const {signOut, getUserInfo, getAccountInfo, setAccountStorage} = useContext(AuthContext);

    const checkUserStatus = async () => {
        try {
            const userInfo = await getUserInfo();
            if (!userInfo || !userInfo.email) return;
            const currentAccount = await getAccountInfo();

            const accountFromApi = await getAccountDetails(currentAccount.name);
            if (!accountFromApi | (accountFromApi.status === 'inactive')) {
                signOut(userInfo.email, userInfo.system, userInfo.account);
                return;
            }
            if (
                (accountFromApi.version && !currentAccount.version) ||
                (accountFromApi.version && currentAccount.version < accountFromApi.version)
            ) {
                await setAccountStorage(accountFromApi);
                window.location.reload();
            }
        } catch (e) {
            console.log('e: ', e);
        }
    };

    useEffect(() => {
        checkUserStatus();
    }, []);

    return null;
};

AccountCheck.propTypes = {
    className: PropTypes.string,
};

export default AccountCheck;
