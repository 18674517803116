/** @format */

import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import {WebRoutes} from './WebRoutes';
import {BaseRoutes} from './BaseRoutes';

const Routes = () => {
    return (
        <Switch>
            <Redirect exact from="/" to="/web/login" />
            <Redirect exact from="/web" to="/web/login" />
            <Route path="/web/" component={WebRoutes} />
            <Route component={BaseRoutes} />
        </Switch>
    );
};

export default Routes;
