/** @format */

import styled from 'styled-components';
import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    avatarWrapper: {
        width: 'unset',
    },
    label: {
        padding: '0 10px',
    },
    s: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        fontSize: theme.spacing(2),
    },
    l: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        fontSize: theme.spacing(5),
    },
    xxxl: {
        width: '11rem',
        height: '11rem',
        fontSize: '5rem',
    },
}));

export const AvatarRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 0px;
    cursor: ${(props) => props.onClick && 'pointer'};
`;

export const Wrapper = styled.span`
    display: flex;
    flex-direction: row;

    ${(props) => props.theme.breakpoints.down('sm')} {
        flex-direction: column;
    }

    .MuiAvatarGroup-avatar {
        border: unset;
    }
`;
