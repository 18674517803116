import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const DialogComponent = props => {
    const { open, onClose, onPositive, onNegative, title, remarks } = props;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {remarks}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onPositive} color="primary" autoFocus>
                    OK
                    </Button>
                <Button onClick={onNegative} color="primary">
                    Cancel
                    </Button>
            </DialogActions>
        </Dialog>
    )
};

DialogComponent.propTypes = {};

export default DialogComponent;
