/** @format */

import {colors} from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';

export default {
    black: white,
    white: black,
    primary: {
        contrastText: white,
        dark: '#1136ac',
        main: '#F1A320',
        light: '#478fec',
    },
    secondary: {
        contrastText: white,
        dark: '#811eba',
        main: '#620ea2',
        light: '#a746de',
    },
    success: {
        contrastText: white,
        dark: colors.green[900],
        main: colors.green[600],
        light: colors.green[400],
    },
    info: {
        contrastText: white,
        dark: colors.blue[900],
        main: colors.blue[600],
        light: colors.blue[400],
    },
    warning: {
        contrastText: white,
        dark: colors.orange[900],
        main: colors.orange[600],
        light: colors.orange[400],
    },
    error: {
        contrastText: white,
        dark: colors.red[900],
        main: colors.red[600],
        light: colors.red[400],
    },
    text: {
        primary: white,
        secondary: '#bdbdbd',
        link: '#1a73e8',
    },
    background: {
        primary: '#000000',
        secondary: '#010101',
        tertiary: '#242527',
    },
    icon: white,
    divider: colors.grey[200],
    card: '#494b4f',
    audit: '#494b4f',
    comment: '#494b4f',
    inputBg: '#494b4f',
    inputText: white,
    modalHeaderBg: '#242527',
};
