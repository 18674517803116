/** @format */

import React from 'react';
import styled from 'styled-components';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MSwitch from '@material-ui/core/Switch';

const Switch = (props) => {
    const {label} = props;

    return (
        <Wrapper>
            <FormGroup row>
                <FormControlLabel
                    {...props}
                    control={<MSwitch {...props} color="primary" checked={props.value} />}
                    label={label}
                />
            </FormGroup>
        </Wrapper>
    );
};

const Wrapper = styled.span`
    .MuiTypography-body1 {
        margin: 0 10px;
    }
`;

export default Switch;
