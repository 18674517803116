/** @format */

import {colors} from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';

export default {
    black,
    white,
    primary: {
        contrastText: white,
        dark: '#1136ac',
        main: '#639dbb',
        light: '#478fec',
    },
    secondary: {
        contrastText: white,
        dark: '#811eba',
        main: '#a2a3a4',
        light: '#a746de',
    },
    success: {
        contrastText: white,
        dark: colors.green[900],
        main: colors.green[600],
        light: colors.green[400],
    },
    info: {
        contrastText: white,
        dark: colors.blue[900],
        main: colors.blue[600],
        light: colors.blue[400],
    },
    warning: {
        contrastText: white,
        dark: colors.orange[900],
        main: colors.orange[600],
        light: colors.orange[400],
    },
    error: {
        contrastText: white,
        dark: colors.red[900],
        main: colors.red[600],
        light: colors.red[400],
    },
    text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600],
        link: colors.blue[600],
    },
    background: {
        primary: '#F4F6F8',
        secondary: white,
        tertiary: white,
    },
    icon: colors.blueGrey[600],
    divider: colors.grey[200],
    card: white,
    audit: white,
    comment: white,
    inputBg: '#e2e2e2',
    inputText: black,
    modalHeaderBg: white,
};
