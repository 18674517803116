/** @format */

import React, {useState} from 'react';
import styled from 'styled-components';
import clsx from 'clsx';

import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputBase from '@material-ui/core/InputBase';

import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const Input = (props) => {
    const {type = 'text', password, error, variant = TYPE.FILLED, ...rest} = props;
    const [showPassword, setShowPassword] = useState(false);

    const globalProps = {
        type,
        id: clsx({
            'outlined-error-helper-text': error || false,
        }),
        ...rest,
        ...(password && {
            type: showPassword ? 'text' : 'password',
            endAdornment: !password ? null : (
                <InputAdornment position="end">
                    <IconButton
                        edge="end"
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                    >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                </InputAdornment>
            ),
        }),
    };

    if (variant === TYPE.OUTLINED) return <OutlinedInput {...globalProps} />;
    if (variant === TYPE.FILLED) {
        return (
            <FilledInputWrapper>
                <FilledInput {...globalProps} />
            </FilledInputWrapper>
        );
    }
    return <InputBase {...globalProps} />;
};

const FilledInputWrapper = styled.span`
    display: contents;

    .MuiFilledInput-root {
        border-radius: 4px;
    }

    .MuiFilledInput-underline:after,
    .MuiFilledInput-underline:before {
        content: unset;
    }
`;

export const TYPE = {
    OUTLINED: 'outlined',
    FILLED: 'filled',
};

export default Input;
