/** @format */

import React from 'react';
import styled from 'styled-components';

import {Typography, Grid, Chip} from '@material-ui/core';
import {capitalize, getColor} from 'tools/Utils';

const Tag = (props) => {
    const {status, icon, children, color, style, ...rest} = props;

    return (
        <>
            <Chip
                label={status ? capitalize(status) : children}
                {...rest}
                style={{
                    backgroundColor: color || getColor(status),
                    color: '#fff',
                    fontWeight: 'bold',
                    ...style,
                }}
            />
            {children}
        </>
    );
};

export const CustomTag = (props) => {
    const {label, icon} = props;

    return (
        <StyledTag {...props}>
            <Grid direction="row" container alignItems="center">
                {icon}
                <Typography variant="caption">{label}</Typography>
            </Grid>
        </StyledTag>
    );
};

export const StyledTag = styled.div`
    display: inline-flex;
    align-items: center;

    padding: 5px 10px;
    border-radius: 10px;
    background-color: ${(props) => props.color || getColor(props.status, props.theme)};

    svg {
        margin-right: 5px;
    }

    * {
        font-size: 12px !important;
        font-weight: 900;
        color: white;
    }
`;

export default Tag;
