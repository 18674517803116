import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    root: {},
    imageContainer: {
        height: 64,
        width: 64,
        margin: '0 auto',
        // border: `1px solid ${theme.palette.divider}`,
        borderRadius: '5px',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    image: {
        width: '100%'
    },
    statsItem: {
        display: 'flex',
        alignItems: 'center'
    },
    statsIcon: {
        color: theme.palette.icon,
        marginRight: theme.spacing(1)
    },
    button: {
        textTransform: 'none',
        height: 44,
        elevation: 3,
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.3,
        borderRadius: 0
    },
    bottomSheetButton: {
        marginLeft: 8,
        marginRight: 8,
        textTransform: 'none',
        height: 44,
        elevation: 3,
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.3,
        borderRadius: 0
    },
    primaryField: {
        marginTop: 10
    },
    inputTextField: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottom: '0px',
        color: '#2c2c2c',
        backgroundColor: '#f1f5f7'
    },
    item: {
        display: 'flex',
        flexDirection: 'column'
    },
    fab: {
        position: 'fixed',
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 20,
        left: 'auto'
    },
    paper: {
        height: '100%'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
}));

export default useStyles;