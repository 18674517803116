/** @format */

import React, {useState, useEffect, useCallback, useContext} from 'react';
import {useDropzone} from 'react-dropzone';

import {FormField} from 'components';
import {Typography, Grid, IconButton} from '@material-ui/core';
import {ImagePreviewWrapper, Container, ThumbsContainer, Image, ThumbInner, Thumb} from './Uploader.style';

import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

import {hyperlinkDownload} from 'tools/Utils';
import {inspectFile, downloadFile} from 'api/DocApproval';

export const Uploader = (props) => {
    const {onChange = () => {}, attachments, disabled, multiple, config, onPreview = () => {}} = props;
    const [files, setFiles] = useState([]);

    const onDrop = useCallback(
        (uploadedFiles) => {
            // create blob for preview
            const f = uploadedFiles.map((file) => {
                return Object.assign(file, {
                    preview: URL.createObjectURL(file),
                });
            });

            const output = multiple ? f : f.slice(0, 1);
            setFiles(output);
            onChange(output);
        },
        [onChange]
    );

    const {getRootProps, getInputProps} = useDropzone({
        multiple,
        onDrop,
        ...config,
    });

    useEffect(() => {
        if (!attachments || attachments.filter((x) => x).length === 0) {
            setFiles([]);
            return;
        }

        // EXAMPLE DATA: ['93981492-2caf-4fa8-820d-8964f83fc3f0']
        const f = attachments.map((attachment) => {
            // for new upload
            if (attachment instanceof File) return attachment;

            // for existing minio
            if (typeof attachment === 'string') {
                return {
                    name: attachment,
                    preview: onPreview(attachment),
                };
            }

            // for existing s3
            if (typeof attachment === 'object') {
                return {
                    type: attachment.contenttype,
                    name: attachment.filename,
                    preview: onPreview(attachment),
                };
            }

            // EXAMPLE DATA: [ File ]
            return attachment;
        });

        setFiles(f);
    }, [attachments]);

    return (
        <section className="container uploader">
            {disabled ? null : (
                <Container {...getRootProps()}>
                    <input {...getInputProps()} />
                    <CloudUploadIcon />
                    <Typography variant="subtitle2">Drag/Upload your attachment here.</Typography>{' '}
                </Container>
            )}
            {files && (
                <ThumbsContainer>
                    {files.map((file) => {
                        const {type, name, preview} = file;
                        const isImage = type && type.includes('image');

                        return (
                            <Thumb
                                key={name}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();

                                    // gracefully download
                                    hyperlinkDownload(preview);
                                }}
                            >
                                <ThumbInner>
                                    <Grid container direction="row" alignItems="center">
                                        <span className="thumb-preview">
                                            {!type || !isImage ? <InsertDriveFileIcon /> : null}
                                            {isImage ? <Image src={preview} /> : null}
                                        </span>
                                        <AttachmentFileName name={name} />
                                    </Grid>
                                </ThumbInner>
                            </Thumb>
                        );
                    })}
                </ThumbsContainer>
            )}
        </section>
    );
};

const AttachmentFileName = ({name}) => {
    const [filename, setFilename] = useState(name);

    useEffect(() => {
        inspectFile(name).then((metaData) => {
            if (metaData && metaData.metaData) setFilename(metaData.metaData.name);
            else setFilename(name);
        });
    }, []);

    return <Typography variant="subtitle2">{filename}</Typography>;
};

export const AttachmentInput = (props) => {
    const {attachments, disabled, onChange, description, labelVariant, label, onDelete, name, preview} = props;
    const [attachment] = attachments || [];
    return (
        <>
            <FormField preview labelVariant={labelVariant} label={label} description={description}>
                {preview && (
                    <>
                        {attachments && attachment instanceof File && (
                            <ImagePreview
                                disabled={disabled}
                                onDelete={onDelete}
                                onClick={() => window.open(attachment.preview)}
                                src={attachment.preview}
                                alt="mycorp-logo"
                            />
                        )}
                        {attachment && typeof attachment === 'string' && (
                            <ImagePreview
                                disabled={disabled}
                                onDelete={onDelete}
                                onClick={() => window.open(downloadFile(attachment))}
                                src={downloadFile(attachment)}
                                alt="mycorp-logo"
                            />
                        )}
                    </>
                )}
                {attachments && attachments.filter((x) => x).length ? null : (
                    <Uploader
                        disabled={disabled}
                        type="file"
                        name={name}
                        attachments={attachments}
                        onPreview={(file) => downloadFile(file)}
                        onChange={onChange}
                    />
                )}
            </FormField>
        </>
    );
};

export const ImagePreview = (props) => {
    const {onDelete, disabled, ...rest} = props;
    const [loaded, setLoaded] = useState(false);
    return (
        <ImagePreviewWrapper>
            {loaded && onDelete && !disabled && (
                <IconButton color="primary" className="delete-button" onClick={onDelete}>
                    <DeleteIcon />
                </IconButton>
            )}
            <img {...rest} onLoad={(e) => setLoaded(true)} />
        </ImagePreviewWrapper>
    );
};

export default Uploader;
