/** @format */

import styled from 'styled-components';

export const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;

    border-width: 2px;
    border-radius: 2px;
    border-style: dashed;
    border-color: #e6e6e6;

    background-color: ${(props) => props.theme.palette.background.tertiary};
    color: ${(props) => props.theme.palette.text.secondary};
    outline: none;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
`;

export const ThumbsContainer = styled.aside`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 16px;
`;

export const Thumb = styled.div`
    display: inline-flex;
    border-radius: 2px;
    padding: 10px;
    box-sizing: border-box;
    cursor: pointer;
    border-bottom: 1px solid #e6e8eb;
    border-top: 1px solid #e6e8eb;

    .thumb-preview {
        margin: 0 10px 0 0;

        ${(props) => props.theme.breakpoints.down('sm')} {
            margin: 10px 10px 10px 0;
        }

        svg,
        img {
            width: 32px;
            height: 32px;
        }
    }
`;

export const ThumbInner = styled.div`
    display: flex;
    min-width: 0;
    overflow: hidden;
`;

export const Image = styled.img`
    display: block;
    width: 32px;
    height: 32px;
`;

export const ImagePreviewWrapper = styled.div`
    position: relative;
    display: inline-block;
    // margin-bottom: 20px;

    img {
        width: ${(props) => props.width || '200px'};
        resize-mode: contain;
        border-radius: 10px;
        cursor: pointer;
    }

    .delete-button {
        position: absolute;
        bottom: -5px;
        right: -10px;
        z-index: 9;
        background-color: white;
        background-color: ${(props) => props.theme.palette.background.tertiary};
        color: ${(props) => props.theme.palette.error.main};
        box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 2px 6px 2px rgba(60, 64, 67, 0.15);
    }
`;
