/** @format */

const axios = require('axios');
const {MYCORP_AUTH_ENDPOINTS} = require('../tools/Constants');

export const getUserStatus = async (email) => {
    try {
        const res = await axios.get(`${MYCORP_AUTH_ENDPOINTS}/api/user/status?system=mycorp&email=${email}`);
        return res.data;
    } catch (e) {
        return e;
    }
};

export const getAccountDetails = async (account) => {
    try {
        const res = await axios.get(`${MYCORP_AUTH_ENDPOINTS}/api/user/account?system=mycorp&name=${account}`);
        return res.data;
    } catch (e) {
        return e;
    }
};
