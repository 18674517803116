/** @format */

import firebase from 'firebase/app';
import * as events from 'events';

import {registerFirebase} from 'api/Firebase';
import 'firebase/messaging';

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
const isWeb = window.location.pathname.includes('web');

export const messageListeners = new events.EventEmitter();

function Firebase() {
    if (!isWeb || isSafari) return;
    this.initialize();
}

Firebase.prototype.initialize = function () {
    try {
        firebase.initializeApp({
            apiKey: 'AIzaSyBXcME5lu0RzsiVc1LpE5LlhDrW8zqSZ9w',
            authDomain: 'cyder-mycorp.firebaseapp.com',
            databaseURL: 'https://cyder-mycorp.firebaseio.com',
            projectId: 'cyder-mycorp',
            storageBucket: 'cyder-mycorp.appspot.com',
            messagingSenderId: '796735183319',
            appId: '1:796735183319:web:6d45f64b041b7681ef5392',
            measurementId: 'G-SXQBPLSNFE',
        });

        this.messaging = firebase.messaging();

        this.messaging.onMessage((payload) => {
            messageListeners.emit('message', payload);
        });
    } catch (e) {
        console.log('Firebase not supported');
    }
};

Firebase.prototype.requestPermission = async function () {
    if (!isWeb) return;

    try {
        // await this.messaging.requestPermission();
        const token = await this.messaging.getToken();
        return token;
    } catch (e) {
        console.log(e);
        return null;
    }
};

Firebase.prototype.registerFirebase = registerFirebase;

export default new Firebase();
