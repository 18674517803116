/** @format */

import React, {useContext} from 'react';
import styled from 'styled-components';
import {ThemeContext} from 'theme';

const Divider = (props) => {
    const {getDarkMode} = useContext(ThemeContext);
    const darkMode = getDarkMode();

    return <StyledDivider darkMode={darkMode}></StyledDivider>;
};

const StyledDivider = styled.span`
    display: block;
    border: none;
    color: white;
    height: 1px;
    margin: 20px 0px;
    background: black;
    background: ${(props) =>
        !props.darkMode
            ? '-webkit-gradient(radial, 50% 50%, 0, 50% 50%, 550, from(#e8e8e8), to(transparent))'
            : '-webkit-gradient(radial, 50% 50%, 0, 50% 50%, 550, from(lightgrey), to(transparent))'};
`;

export default Divider;
