/** @format */

import React, {lazy} from 'react';

export const Icons = lazy(() => import('./Icons/Icons.js'));
export const Register = lazy(() => import('./Register/Register.js'));
export const Login = lazy(() => import('./Login/Login.js'));
export const Dashboard = lazy(() => import('./Dashboard/Dashboard.js'));
export const ForgotPassword = lazy(() => import('./ForgotPassword/ForgotPassword.js'));
export const NotFound = lazy(() => import('./NotFound/NotFound.js'));

export const DocApprovalList = lazy(() => import('./DocApproval/DocApprovalList/DocApprovalList.js'));
export const DocApprovalListWeb = lazy(() => import('./DocApproval/DocApprovalList/DocApprovalList.web'));

export const ActiveTaskList = lazy(() => import('./InstaTask/ActiveTaskList/ActiveTaskList.js'));
export const ActiveTaskListWeb = lazy(() => import('./InstaTask/ActiveTaskList/ActiveTaskList.web'));

export const Expenses = lazy(() => import('./Expenses/Expenses.js'));
export const Timesheet = lazy(() => import('./Timesheet/Timesheet.js'));
export const TimesheetAnalytics = lazy(() => import('./Timesheet/TimesheetAnalytics.js'));
export const Leave = lazy(() => import('./Leave/Leave'));
export const Survey = lazy(() => import('./Survey/Survey'));
export const AuthoriseList = lazy(() => import('./Authorise/AuthoriseList.js'));
export const NotifyList = lazy(() => import('./Notify/NotifyList.js'));

export const About = lazy(() => import('./About/About.js'));
export const Feedback = lazy(() => import('./About/Feedback'));
export const SendNotify = lazy(() => import('./About/SendNotifyPage'));
export const UserManagement = lazy(() => import('./UserManagement/UserManagement.js'));
export const GroupManagement = lazy(() => import('./UserManagement/GroupManagement.js'));
export const Contact = lazy(() => import('./Contact/Contact.js'));
export const Organisation = lazy(() => import('./Organisation/Organisation'));
export const Group = lazy(() => import('./Group/Group'));
export const Analytics = lazy(() => import('./Analytics/Analytics'));

export const Settings = lazy(() => import('./Settings/Settings.js'));
export const ChangeLogo = lazy(() => import('./ChangeLogo/ChangeLogo.js'));
export const ChangePassword = lazy(() => import('./ChangePassword/ChangePassword.js'));
export const ChangePIN = lazy(() => import('./ChangePIN/ChangePIN.js'));
export const ChangeProfile = lazy(() => import('./ChangeProfile/ChangeProfile.js'));
export const ResetPassword = lazy(() => import('./ResetPassword/ResetPassword.js'));
