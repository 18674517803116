/** @format */
import React, {memo, useContext, useState, useCallback, useEffect} from 'react';
import clsx from 'clsx';

import ContentLoader from 'react-content-loader';
import {CardContent, Typography, Box, IconButton, Menu as MuiMenu, MenuItem} from '@material-ui/core';
import {Radio, SearchInput, ButtonTypes} from 'components';
import {NotFound} from 'views';
import {
    SortBarWrapper,
    MenusWrapper,
    TableFunctions,
    InnerFunctions,
    StyledTableItem,
    useStyles,
    Wrapper,
} from './Table.style';

import ShuffleIcon from '@material-ui/icons/Shuffle';
import SortIcon from '@material-ui/icons/Sort';

import {ThemeContext} from 'theme';
import {useForceUpdate, useResize} from 'tools/CustomHooks';
import {capitalize} from 'tools/Utils';

const Table = (props) => {
    const {data, sortableFields, children, sortable, searchable, advanceSearch} = props;
    const [localData, setLocalData] = useState(null);
    const forceUpdate = useForceUpdate();

    const onSearch = ({possibleData}) => {
        setLocalData(possibleData);
    };

    const onSort = ({sortedData}) => {
        setLocalData(sortedData);
        forceUpdate();
    };

    useEffect(() => {
        // delegate update on localData to searchbar if is used
        if (!searchable) setLocalData(data);
    }, [data]);

    // auto clear loading by assigning empty array after 10sec of waiting time
    useEffect(() => {
        if (data) return;
        const timer = setTimeout(() => setLocalData([]), 10000);
        return () => clearTimeout(timer);
    }, [data]);

    return (
        <Wrapper>
            {searchable || sortable ? (
                <TableFunctions>
                    {advanceSearch && advanceSearch()}
                    <InnerFunctions>
                        {searchable && <SearchBar data={data} onSearch={onSearch} />}
                        {sortable && <SortBar fields={sortableFields} data={localData} onSort={onSort} />}
                    </InnerFunctions>
                </TableFunctions>
            ) : null}
            {!localData ? (
                <TableLoader total={4} />
            ) : (
                <>
                    {localData && localData.length ? (
                        localData.map((x, index) => {
                            if (!children) return;
                            return <span key={index}>{children({item: x, index, TableItem, data: localData})}</span>;
                        })
                    ) : (
                        <NotFound imageOnly />
                    )}
                </>
            )}
        </Wrapper>
    );
};

const SortBar = memo((props) => {
    const {onSort, data, originalData, fields} = props;
    const [value, setValue] = useState('default');
    const [anchorEl, setAnchorEl] = useState(null);

    const options = fields.map((x) => ({label: capitalize(x.label), value: x.value}));

    const sortData = useCallback(
        (sortBy) => {
            if (!sortBy || !data) return;

            try {
                const sortedData = data.sort((a, b) => {
                    const valueA = a[sortBy];
                    const valueB = b[sortBy];
                    if (valueA < valueB) return -1;
                    if (valueA > valueB) return 1;
                    return 0;
                });

                onSort && onSort({sortedData});
            } catch (e) {
                console.log('e: ', e);
            }
        },
        [data]
    );

    const onChange = (e) => {
        const {name} = e.target;
        setValue(name);

        if (name === 'default') {
            onSort && onSort({sortedData: originalData});
        } else {
            sortData(name);
        }
    };

    const onShuffle = useCallback(() => {
        if (!data) return;
        onSort && onSort({sortedData: data.reverse()});
    }, [data]);

    return (
        <SortBarWrapper>
            <IconButton color="none" variant={ButtonTypes.CONTAINED} onClick={(e) => setAnchorEl(e.currentTarget)}>
                <SortIcon />
            </IconButton>
            <IconButton color="none" variant={ButtonTypes.CONTAINED} onClick={onShuffle}>
                <ShuffleIcon />
            </IconButton>
            <MuiMenu keepMounted anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
                <MenusWrapper>
                    <Radio group groupId="sort" title="Sort by" options={options} value={value} onChange={onChange} />
                </MenusWrapper>
            </MuiMenu>
        </SortBarWrapper>
    );
});

export const SearchBar = memo((props) => {
    const {onSearch = () => {}, data = []} = props;
    const [keyword, setKeyword] = useState('');
    const styles = useStyles();

    const finsPossible = (list, target) => {
        if (!list || !target) return [];

        let possibleData = [];
        for (let i = 0; i < list.length; i++) {
            const item = list[i];
            const values = JSON.stringify(Object.values(item)).toLowerCase();
            if (values.includes(target)) possibleData.push(item);
        }

        return possibleData;
    };

    const onLocalSearch = (value) => {
        if (value.length === 0 || !data) {
            onSearch({keyword: value, possibleData: data});
            return;
        }
        const possibleData = finsPossible(data, value.toLowerCase());
        onSearch({keyword: value, possibleData});
    };

    // trigger search on latest input only
    let timeout;
    const searchProxy = useCallback(
        (value, timer = 1000) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                clearTimeout(timeout);
                onLocalSearch(value);
            }, timer);
        },
        [timeout, data]
    );

    useEffect(() => {
        searchProxy(keyword, 0);
    }, [data]);

    return (
        <span className={clsx(styles.root)}>
            <SearchInput
                value={keyword}
                placeholder="Search..."
                onChange={(e) => {
                    const {value} = e.currentTarget;
                    setKeyword(value);
                    searchProxy(value);
                }}
            />
        </span>
    );
});

export const TableItem = (props) => {
    const {children, divider = true, className, ...rest} = props;
    return (
        <StyledTableItem {...rest} className={clsx(className)}>
            {children}
            {divider ? <divider /> : <br />}
        </StyledTableItem>
    );
};

export const TableLoader = (props) => {
    const {total = 1} = props;
    const styles = useStyles();
    const {theme, getDarkMode} = useContext(ThemeContext);
    const isDarkMode = getDarkMode();
    const width = useResize();

    const getViewBox = () => {
        if (width >= theme.breakpoints.values['sm'] && width <= theme.breakpoints.values['md']) {
            return '0 0 280 35';
        }

        if (width >= theme.breakpoints.values['md'] && width <= theme.breakpoints.values['lg']) {
            return '0 0 250 35';
        }

        if (width >= theme.breakpoints.values['lg']) {
            return '0 0 300 35';
        }

        return '0 0 100 35';
    };

    return (
        <CardContent className={styles.cardContent}>
            {new Array(total).fill('').map((_, i) => {
                return (
                    <ContentLoader
                        key={i}
                        viewBox={getViewBox()}
                        backgroundColor={isDarkMode ? '#333' : '#f5f6f7'}
                        foregroundColor={isDarkMode ? '#999' : '#eee'}
                    >
                        <rect x="0" y="15" rx="1" ry="1" width="30" height="2" />
                        <rect x="0" y="20" rx="1" ry="1" width="50" height="2" />
                        <rect x="0" y="25" rx="1" ry="1" width="30" height="2" />
                        <rect x="0" y="30" rx="1" ry="1" width="50" height="2" />
                    </ContentLoader>
                );
            })}
        </CardContent>
    );
};

export default Table;
