/** @format */

import React, {Component} from 'react';
import {myCorpNotifySocketSocket} from './Notify';

export const NotifyContext = React.createContext();

export default class SocketProvider extends Component {
    render() {
        return (
            <NotifyContext.Provider
                value={{
                    myCorpNotifySocketSocket,
                }}
            >
                {this.props.children}
            </NotifyContext.Provider>
        );
    }
}
