/** @format */

import React, {Component} from 'react';
import {myCorpContactSocket} from './Contact';

export const ContactContext = React.createContext();

export default class ContactSocketProvider extends Component {
    render() {
        return (
            <ContactContext.Provider
                value={{
                    myCorpContactSocket,
                }}
            >
                {this.props.children}
            </ContactContext.Provider>
        );
    }
}
