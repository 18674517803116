/** @format */

import React from 'react';
import {Button as MButton} from '@material-ui/core';

const Button = (props) => {
    const {children, color = 'primary', variant = TYPE.CONTAINED} = props;

    return (
        <MButton color={color} variant={variant} {...props}>
            {children}
        </MButton>
    );
};

export const TYPE = {
    CONTAINED: 'contained',
    OUTLINED: 'outlined',
};

export default Button;
