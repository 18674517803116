/** @format */

import React from 'react';
import styled from 'styled-components';

import MSelect from '@material-ui/core/Select';
import MMenuItem from '@material-ui/core/MenuItem';

const Select = (props) => {
    const {options, getOptionValue, getOptionLabel, ...rest} = props;

    return (
        <SelectWrapper>
            <MSelect {...rest}>
                <MenuItem value="">
                    <em>None</em>
                </MenuItem>
                {options.map((opt, i) => {
                    if (opt.disabled) return null;

                    return (
                        <MenuItem key={i} value={getOptionValue ? getOptionValue(opt) : opt.value}>
                            {getOptionLabel ? getOptionLabel(opt) : opt.label}
                        </MenuItem>
                    );
                })}
            </MSelect>
        </SelectWrapper>
    );
};

const MenuItem = styled(MMenuItem)`
    .MuiListItem-button {
        transition: background-color 25ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
`;

const SelectWrapper = styled.span`
    display: contents;

    .MuiFilledInput-root {
        border-radius: 4px;
    }

    .MuiFilledInput-underline:after,
    .MuiFilledInput-underline:before {
        content: unset;
    }
`;

export const TYPE = {
    OUTLINED: 'outlined',
    FILLED: 'filled',
};

export default Select;
