/** @format */

import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Router} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import VersionCheck from 'components/VersionCheck';
import UserCheck from 'components/UserCheck';
import AccountCheck from 'components/AccountCheck';
import NotifyCheck from 'components/NotifyCheck';
import Routes from 'routes/Routes';

import {AlertProvider} from 'contexts/Alert';
import {AuthProvider} from 'contexts/Auth';
import {ThemeProvider} from 'theme';
import {Sockets} from 'layouts';

import Firebase, {messageListeners} from 'tools/Firebase';
import 'tools/Chart';
import './serviceWorker';

import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';

const browserHistory = createBrowserHistory();
const isWeb = window.location.pathname.includes('web');
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

if (isWeb && !isSafari) {
    messageListeners.addListener('message', (payload) => {
        console.log('Received notification: ', payload);
    });
}

export default () => {
    const registerFirebase = async () => {
        if (!isWeb || isSafari) return;

        try {
            const fbToken = await Firebase.requestPermission();
            console.log('Firebase: ', fbToken);
            if (!fbToken) return;

            localStorage.setItem('@mycorp-firebase-token', fbToken);
            await Firebase.registerFirebase();
        } catch (err) {
            console.log('Unable to register Firebase token: ', err);
        }
    };

    useEffect(() => {
        registerFirebase();
    }, []);

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <ThemeProvider>
                <Router history={browserHistory}>
                    <AuthProvider>
                        <AlertProvider>
                            <Sockets>
                                <Body>
                                    <VersionCheck />
                                    <NotifyCheck history={browserHistory} />
                                    <UserCheck />
                                    <AccountCheck />
                                    <Routes />
                                </Body>
                            </Sockets>
                        </AlertProvider>
                    </AuthProvider>
                </Router>
            </ThemeProvider>
        </MuiPickersUtilsProvider>
    );
};

const Body = styled.div`
    background-color: ${(props) => props.theme.palette.background.secondary};
    height: 100%;
`;
