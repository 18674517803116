/** @format */

import React, {useState, useContext} from 'react';

import Switch from '@material-ui/core/Switch';
import {Label, FormFieldTypes, Alert, FormField, Input} from 'components';

import {AuthContext} from 'contexts/Auth';
import {generateHash} from 'tools/Utils';
import NativeUtils from 'tools/NativeUtils';

export const LockMode = (props) => {
    const {history} = props;

    const [pin, setPin] = useState('');
    const [open, setOpen] = useState(false);

    const isWeb = window.location.pathname.includes('web');
    const authContext = useContext(AuthContext);
    const userInfo = authContext.getUserInfo() || {};

    const onLockMode = (isLocked) => {
        // if (isLocked) {
        //     setOpen(true);
        //     return;
        // }
        onSubmit(isLocked);
    };

    const onSubmit = async (lockMode) => {
        try {
            // await authContext.updateUser({...userInfo, lockMode, lockModePin: pin && generateHash(pin)});
            await authContext.updateUser({...userInfo, lockMode});
        } catch (e) {
            console.log('e: ', e);
        } finally {
            setOpen(false);
            NativeUtils.send('findUser');
            if (lockMode && isWeb) history.push('/web/dashboard/home');
            if (lockMode && !isWeb) NativeUtils.send('onLockModeEnabled');
        }
    };

    return (
        <>
            {/* <LockModeAlert
                open={open}
                label="Please enter a 6 digits pin and click submit"
                description="Choose a strong pin that you can remember easily."
                value={pin}
                onChange={(e) => {
                    const {value} = e.currentTarget;
                    if (value.length <= 6) setPin(value);
                }}
                onNegative={() => {
                    setPin('');
                    setOpen(false);
                }}
                onPositive={{
                    label: 'Submit',
                    disabled: pin.length !== 6,
                    onClick: () => onSubmit(true, pin),
                }}
            /> */}
            <Switch
                key={userInfo.lockMode} // mobile: to remount component
                edge="end"
                color="primary"
                disabled={userInfo.lockModePin == null || userInfo.lockModePin.trim().length <= 0}
                checked={userInfo.lockMode}
                onChange={(e) => {
                    const {checked} = e.currentTarget;
                    onLockMode(checked);
                }}
            />
        </>
    );
};

export const LockModeAlert = (props) => {
    const {label, description, onNegative, onPositive, open, onChange, value} = props;

    return (
        <Alert open={open} title="Lock Mode Pin" onNegative={onNegative} onPositive={onPositive}>
            {label || description ? <Label labelVariant="label2" label={label} description={description} /> : null}
            <FormField label="Pin" variant={FormFieldTypes.FILLED}>
                <Input password name="pin" value={value} onChange={onChange} />
            </FormField>
        </Alert>
    );
};
