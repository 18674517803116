/** @format */

import React, {Component} from 'react';
import {myCorpAuditSocket} from 'sockets/Audit/Audit';

export const AuditContext = React.createContext();

export default class AuditSocketProvider extends Component {
    render() {
        return (
            <AuditContext.Provider
                value={{
                    myCorpAuditSocket,
                }}
            >
                {this.props.children}
            </AuditContext.Provider>
        );
    }
}
