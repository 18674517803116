/** @format */

import socketIOClient from 'socket.io-client';
import {generateHash} from 'tools/Utils';

const uuidv1 = require('uuid/v1');
const {MYCORP_AUTH_ENDPOINTS} = require('tools/Constants');

class MyCorpAuthSocket {
    _socket = null;

    constructor(withInit) {
        if (!withInit) this.init(() => {});
    }

    init(onConnect) {
        this._socket = socketIOClient(MYCORP_AUTH_ENDPOINTS, {
            path: '/auth',
            rememberTransport: false,
            transports: ['websocket'],
            query: {
                system: 'mycorp',
            },
        });

        this._socket.on('socketInitialized', () => {
            onConnect();
        });
    }

    updateUserInfoListener = (filter) => {
        this._socket.emit('updateUserInfoListener', filter);
    };

    subscribeUserInfo = (callback) => {
        this._socket.on('userInfo', (response) => {
            callback && callback(response && response[0]);
        });
    };

    updateUsersListener = (filter) => {
        this._socket.emit('updateUsersListener', filter);
    };

    updateGroupsListener = (filter) => {
        this._socket.emit('updateGroupsListener', filter);
    };

    subscribeUsers = (callback) => {
        this._socket.on('users', (response) => {
            callback && callback(response && response);
        });
    };

    subscribeGroups = (callback) => {
        this._socket.on('groups', (response) => {
            callback && callback(response && response);
        });
    };

    doesAccountExist = (account) => {
        const correlationId = uuidv1();
        return new Promise(async (resolve, reject) => {
            this._socket.emit('findAccounts', {
                correlationId,
                account,
                system: 'mycorp',
            });

            this._socket.on(correlationId, (response) => {
                if (response && response.length) resolve(true);
                else resolve(false);
            });
        });
    };

    doesGroupCodeExist = (code, account) => {
        const correlationId = uuidv1();
        return new Promise(async (resolve, reject) => {
            this._socket.emit('findGroup', {
                correlationId,
                form: {
                    code: code.toLowerCase(),
                    system: 'mycorp',
                    account,
                },
            });

            this._socket.on(correlationId, (response) => {
                if (response && response.length) resolve(true);
                else resolve(false);
            });
        });
    };

    doesEmailExist = (email) => {
        const correlationId = uuidv1();
        return new Promise(async (resolve, reject) => {
            this._socket.emit('findUser', {
                correlationId,
                form: {
                    email: email.toLowerCase(),
                    system: 'mycorp',
                },
            });

            this._socket.on(correlationId, (response) => {
                if (response && response.length) resolve(true);
                else resolve(false);
            });
        });
    };

    doesPhoneExist = (phone) => {
        const correlationId = uuidv1();
        return new Promise(async (resolve, reject) => {
            this._socket.emit('findUser', {
                correlationId,
                form: {
                    phone: phone.toLowerCase(),
                    system: 'mycorp',
                },
            });

            this._socket.on(correlationId, (response) => {
                if (response && response.length) resolve(true);
                else resolve(false);
            });
        });
    };

    registerAccount = (payload) => {
        const correlationId = uuidv1();

        return new Promise((resolve) => {
            this._socket.emit('registerAccount', {correlationId, ...payload, password: generateHash(payload.password)});

            this._socket.on(correlationId, (response) => {
                resolve(response);
            });
        });
    };

    createUser = (payload) => {
        const correlationId = uuidv1();
        // Payload is similar to registerAccount payload with one additional field: isAdmin -> Boolean
        this._socket.emit('createUser', {correlationId, ...payload, password: generateHash(payload.password)});

        return new Promise((resolve) => {
            this._socket.on(correlationId, (response) => {
                resolve(response);
            });
        });
    };

    createGroup = (payload) => {
        const correlationId = uuidv1();
        this._socket.emit('createGroup', {correlationId, ...payload});

        return new Promise((resolve) => {
            this._socket.on(correlationId, (response) => {
                resolve(response);
            });
        });
    };

    decodeToken = (token) => {
        const correlationId = uuidv1();
        this._socket.emit('decodeToken', {correlationId, token});
        return new Promise((resolve) => {
            this._socket.on(correlationId, (response) => {
                resolve(response);
            });
        });
    };

    decodeForgotPasswordToken = (token) => {
        const correlationId = uuidv1();
        this._socket.emit('decodeForgotPasswordToken', {correlationId, token});
        return new Promise((resolve) => {
            this._socket.on(correlationId, (response) => {
                resolve(response);
            });
        });
    };

    getGroupsByAccount = (account) => {
        const correlationId = uuidv1();
        return new Promise(async (resolve, reject) => {
            this._socket.emit('findGroup', {
                correlationId,
                form: {
                    system: 'mycorp',
                    account,
                },
            });

            this._socket.on(correlationId, (response) => {
                if (response && response.length) resolve(response);
                else reject(response);
            });
        });
    };

    getUsersByAccount = (account) => {
        const correlationId = uuidv1();
        return new Promise(async (resolve, reject) => {
            this._socket.emit('findUserNoToken', {
                correlationId,
                form: {
                    system: 'mycorp',
                    account,
                },
            });

            this._socket.on(correlationId, (response) => {
                if (response && response.length) resolve(response);
                else reject(response);
            });
        });
    };

    getUsers = () => {
        const correlationId = uuidv1();
        return new Promise(async (resolve, reject) => {
            this._socket.emit('findUserNoToken', {
                correlationId,
                form: {
                    system: 'mycorp',
                },
            });

            this._socket.on(correlationId, (response) => {
                if (response && response.length) resolve(response);
                else reject(response);
            });
        });
    };

    findUser = (email) => {
        const correlationId = uuidv1();
        return new Promise(async (resolve, reject) => {
            this._socket.emit('findUser', {
                correlationId,
                form: {
                    email: email.toLowerCase(),
                    system: 'mycorp',
                },
            });

            this._socket.on(correlationId, (response) => {
                if (response && response.length) resolve(response[0]);
                else reject(response);
            });
        });
    };

    findUserByPhone = (phone) => {
        const correlationId = uuidv1();
        return new Promise(async (resolve, reject) => {
            this._socket.emit('findUser', {
                correlationId,
                form: {
                    phone: phone.toLowerCase(),
                    system: 'mycorp',
                },
            });

            this._socket.on(correlationId, (response) => {
                if (response && response.length) resolve(response[0]);
                else reject(response);
            });
        });
    };

    findUserNoToken = (email) => {
        const correlationId = uuidv1();
        return new Promise(async (resolve, reject) => {
            this._socket.emit('findUserNoToken', {
                correlationId,
                form: {
                    email: email.toLowerCase(),
                    system: 'mycorp',
                },
            });

            this._socket.on(correlationId, (response) => {
                if (response && response.length) resolve(response[0]);
                else reject(response);
            });
        });
    };

    findAccounts = (account, system) => {
        const correlationId = uuidv1();
        return new Promise(async (resolve, reject) => {
            this._socket.emit('findAccounts', {
                correlationId,
                account,
                system,
            });

            this._socket.on(correlationId, (response) => {
                if (response && response.length) resolve(response[0]);
                else reject(response);
            });
        });
    };

    updateAccount = (form, params) => {
        const correlationId = uuidv1();
        return new Promise(async (resolve, reject) => {
            this._socket.emit('updateAccount', {
                correlationId,
                form,
                params,
            });

            this._socket.on(correlationId, (response) => {
                if (response && response.ok) resolve(response);
                else reject(response);
            });
        });
    };

    deleteUser = (email) => {
        const correlationId = uuidv1();
        const system = 'mycorp';

        return new Promise(async (resolve, reject) => {
            this._socket.emit('deleteUser', {
                correlationId,
                email,
                system,
            });

            this._socket.on(correlationId, (response) => {
                if (response && response.ok) resolve(response);
                else reject(response);
            });
        });
    };

    updateUser = (userInfo) => {
        const correlationId = uuidv1();

        // jwt token generate upon successfully login - not stored data
        let form = userInfo;
        delete form.token;

        return new Promise(async (resolve, reject) => {
            this._socket.emit('updateUser', {
                correlationId,
                form,
            });

            this._socket.on(correlationId, (response) => {
                if (response && response.ok) resolve(response);
                else reject(response);
            });
        });
    };

    deleteGroup = (code, account) => {
        const correlationId = uuidv1();
        const system = 'mycorp';

        return new Promise(async (resolve, reject) => {
            this._socket.emit('deleteGroup', {
                correlationId,
                code,
                system,
                account,
            });

            this._socket.on(correlationId, (response) => {
                if (response && response.ok) resolve(response);
                else reject(response);
            });
        });
    };

    updateGroup = (groupInfo) => {
        const correlationId = uuidv1();

        // jwt token generate upon successfully login - not stored data
        let form = groupInfo;
        delete form.token;

        return new Promise(async (resolve, reject) => {
            this._socket.emit('updateGroup', {
                correlationId,
                form,
            });

            this._socket.on(correlationId, (response) => {
                if (response && response.ok) resolve(response);
                else reject(response);
            });
        });
    };

    getGroupsByEmail = (email, account) => {
        const correlationId = uuidv1();

        return new Promise(async (resolve, reject) => {
            this._socket.emit('getGroupsByEmail', {
                correlationId,
                email,
                account,
            });

            this._socket.on(correlationId, (response) => {
                resolve(response);
            });
        });
    };

    getUsersByGroupCode = (code, account) => {
        const correlationId = uuidv1();

        return new Promise(async (resolve, reject) => {
            this._socket.emit('getUsersByGroupCode', {
                correlationId,
                code,
                account,
            });

            this._socket.on(correlationId, (response) => {
                resolve(response);
            });
        });
    };

    updateUserGroupByEmail = (email, groupCodes, account) => {
        const correlationId = uuidv1();

        return new Promise(async (resolve, reject) => {
            this._socket.emit('updateUserGroupByEmail', {
                correlationId,
                email,
                groupCodes,
                account,
            });

            this._socket.on(correlationId, (response) => {
                if (response && response.ok) resolve(response);
                else reject(response);
            });
        });
    };

    updateUserGroupByGroupCode = (code, emails, account) => {
        const correlationId = uuidv1();

        return new Promise(async (resolve, reject) => {
            this._socket.emit('updateUserGroupByGroupCode', {
                correlationId,
                code,
                emails,
                account,
            });

            this._socket.on(correlationId, (response) => {
                if (response && response.ok) resolve(response);
                else reject(response);
            });
        });
    };

    verifyPin = (email, account, system, pin) => {
        const correlationId = uuidv1();
        return new Promise(async (resolve, reject) => {
            this._socket.emit('verifyLockModePin', {
                correlationId,
                email: email.toLowerCase(),
                account,
                system,
                pin: generateHash(pin),
            });

            this._socket.on(correlationId, (response) => {
                if (response) resolve(response);
                else reject(response);
            });
        });
    };

    loginCheck = (email, password, system, account) => {
        const correlationId = uuidv1();
        return new Promise(async (resolve, reject) => {
            this._socket.emit('loginNoUpdateSession', {
                correlationId,
                email: email.toLowerCase(),
                password: generateHash(password),
                account,
                system,
            });

            this._socket.on(correlationId, (response) => {
                if (response && response.length) resolve(response[0]);
                else reject(response);
            });
        });
    };

    updateLastLogin = (email, sessionId, sessionUserAgent) => {
        const correlationId = uuidv1();
        return new Promise(async (resolve, reject) => {
            this._socket.emit('updateLastLogin', {
                correlationId,
                email: email.toLowerCase(),
                sessionId,
                sessionUserAgent,
            });

            this._socket.on(correlationId, (response) => {
                if (response && response.length) resolve(response[0]);
                else reject(response);
            });
        });
    };

    sendOTPToken = (email, phone, account, system) => {
        const correlationId = uuidv1();
        return new Promise(async (resolve, reject) => {
            this._socket.emit('sendOTPToken', {
                correlationId,
                email: email.toLowerCase(),
                phone,
                account,
                system,
            });

            this._socket.on(correlationId, (response) => {
                if (response) resolve({});
                else reject({});
            });
        });
    };

    verifyOTPToken = (email, otpToken, account, system) => {
        const correlationId = uuidv1();
        return new Promise(async (resolve, reject) => {
            this._socket.emit('verifyOTPToken', {
                correlationId,
                email: email.toLowerCase(),
                otpToken,
                account,
                system,
            });

            this._socket.on(correlationId, (response) => {
                if (response && response.length) resolve(response[0]);
                else reject(response);
            });
        });
    };

    login = (email, password, system, account) => {
        const correlationId = uuidv1();
        return new Promise(async (resolve, reject) => {
            this._socket.emit('login', {
                correlationId,
                email: email.toLowerCase(),
                password: generateHash(password),
                account,
                system,
            });

            this._socket.on(correlationId, (response) => {
                if (response && response.length) resolve(response[0]);
                else reject(response);
            });
        });
    };

    clearToken = (currentFirebaseToken) => {
        const correlationId = uuidv1();
        return new Promise(async (resolve, reject) => {
            console.log('currentFirebaseToken: ', currentFirebaseToken);
            this._socket.emit('clearToken', {
                correlationId,
                currentFirebaseToken,
            });

            this._socket.on(correlationId, (response) => {
                resolve(response);
            });
        });
    };

    logout = (email, system, account, currentFirebaseToken) => {
        const correlationId = uuidv1();
        return new Promise(async (resolve, reject) => {
            this._socket.emit('logout', {
                correlationId,
                email: email.toLowerCase(),
                account,
                system,
                currentFirebaseToken,
            });

            this._socket.on(correlationId, (response) => {
                resolve(response);
            });
        });
    };

    forgotPassword = (email, account, system) => {
        const correlationId = uuidv1();
        return new Promise(async (resolve, reject) => {
            this._socket.emit('forgotPassword', {
                correlationId,
                email: email.toLowerCase(),
                account,
                system,
            });
            this._socket.on(correlationId, (response) => {
                if (response && response.dataset && response.dataset.ok == 1) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
        });
    };

    resetPassword = (email, password, account, system) => {
        const correlationId = uuidv1();
        return new Promise(async (resolve, reject) => {
            this._socket.emit('resetPassword', {
                correlationId,
                email: email.toLowerCase(),
                password: generateHash(password),
                account,
                system,
            });

            this._socket.on(correlationId, (response) => {
                console.log(`[${correlationId}] resetPassword, result: `, response.dataset.ok);
                if (response && response.dataset && response.dataset.ok == 1) {
                    console.log('valid');
                    resolve(response);
                } else {
                    console.log('invalid');
                    reject(response);
                }
            });
        });
    };
}

export const myCorpAuthSocket = new MyCorpAuthSocket(false);
export const myCorpAuthSocketWithInit = new MyCorpAuthSocket(true);
