/** @format */

import React, {useMemo, useContext} from 'react';
import {Switch} from 'react-router-dom';

import {RouteWithLayout} from 'components';
import {BaseRoutes} from './BaseRoutes';
import {Member as MemberLayout, Sockets as SocketsLayout} from 'layouts';

import {AuthContext} from 'contexts/Auth';

import {
    Expenses,
    DocApprovalListWeb,
    ActiveTaskListWeb,
    Dashboard,
    ChangePassword,
    ChangePIN,
    ChangeLogo,
    ChangeProfile,
    NotifyList,
    AuthoriseList,
    Settings,
    Login,
    ForgotPassword,
    ResetPassword,
    About,
    Timesheet,
    TimesheetAnalytics,
    Register,
    UserManagement,
    GroupManagement,
    Feedback,
    Leave,
    Survey,
    Contact,
    Organisation,
    Group,
    SendNotify,
    Analytics,
} from 'views';

export const WebRoutes = () => {
    const {getUserInfo} = useContext(AuthContext);
    const {lockMode} = getUserInfo();

    return (
        <Switch>
            <RouteWithLayout exact publicPage path="/web/register" component={Register} layout={SocketsLayout} />
            <RouteWithLayout exact publicPage path="/web/login" component={Login} layout={SocketsLayout} />
            <RouteWithLayout exact privatePage path="/web/dashboard/home" component={Dashboard} layout={MemberLayout} />
            <RouteWithLayout
                exact
                privatePage
                locked={lockMode}
                path="/web/dashboard/lock"
                component={Dashboard}
                layout={MemberLayout}
            />
            <RouteWithLayout
                exact
                publicPage
                path="/web/forgot-password"
                component={ForgotPassword}
                layout={SocketsLayout}
            />
            <RouteWithLayout
                exact
                publicPage
                path="/web/reset-password/:token"
                component={ResetPassword}
                layout={SocketsLayout}
            />
            <RouteWithLayout
                exact
                privatePage
                label="Change Password"
                path="/web/change-password"
                component={ChangePassword}
                layout={MemberLayout}
            />
            <RouteWithLayout
                exact
                privatePage
                label="Change PIN"
                path="/web/change-pin"
                component={ChangePIN}
                layout={MemberLayout}
            />
            <RouteWithLayout
                exact
                privatePage
                label="Change Profile"
                path="/web/change-profile"
                component={ChangeProfile}
                layout={MemberLayout}
                locked={lockMode}
            />
            <RouteWithLayout
                exact
                privatePage
                label="Change Logo"
                path="/web/change-logo/"
                component={ChangeLogo}
                layout={MemberLayout}
                locked={lockMode}
            />
            <RouteWithLayout
                exact
                privatePage
                label="InstaNotify"
                path="/web/notify"
                component={NotifyList}
                layout={MemberLayout}
                locked={lockMode}
            />
            <RouteWithLayout
                exact
                privatePage
                label="InstaSecure"
                path="/web/authenticate"
                component={AuthoriseList}
                layout={MemberLayout}
                locked={lockMode}
            />
            <RouteWithLayout
                exact
                privatePage
                label="InstaAPV"
                path="/web/instaapv"
                component={DocApprovalListWeb}
                layout={MemberLayout}
                locked={lockMode}
            />
            <RouteWithLayout
                exact
                privatePage
                label="InstaAnalytics"
                path="/web/analytics"
                component={Analytics}
                layout={MemberLayout}
                locked={lockMode}
            />
            <RouteWithLayout
                exact
                privatePage
                label="InstaTask"
                path="/web/instatask"
                component={ActiveTaskListWeb}
                layout={MemberLayout}
                locked={lockMode}
            />
            <RouteWithLayout
                exact
                privatePage
                iframe
                label="InstaXpense"
                path="/web/expenses"
                component={Expenses}
                layout={MemberLayout}
                locked={lockMode}
            />
            <RouteWithLayout
                exact
                privatePage
                iframe
                label="InstaTime"
                path="/web/timesheet"
                component={Timesheet}
                layout={MemberLayout}
                locked={lockMode}
            />
            <RouteWithLayout
                exact
                privatePage
                iframe
                label="Timesheet Analytics"
                path="/web/timesheet/analytics"
                component={TimesheetAnalytics}
                layout={MemberLayout}
                locked={lockMode}
            />
            <RouteWithLayout
                exact
                privatePage
                iframe
                label="InstaSurvey"
                path="/web/survey"
                component={Survey}
                layout={MemberLayout}
                locked={lockMode}
            />
            <RouteWithLayout
                exact
                privatePage
                iframe
                label="InstaLeave"
                path="/web/leave"
                component={Leave}
                layout={MemberLayout}
                locked={lockMode}
            />
            <RouteWithLayout
                exact
                privatePage
                label="Settings"
                path="/web/settings"
                component={Settings}
                layout={MemberLayout}
                locked={lockMode}
            />
            <RouteWithLayout
                exact
                privatePage
                label="About"
                path="/web/about"
                component={About}
                layout={MemberLayout}
            />
            <RouteWithLayout
                exact
                privatePage
                label="Feedback"
                path="/web/feedback"
                component={Feedback}
                layout={MemberLayout}
            />
            <RouteWithLayout
                exact
                privatePage
                label="SendNotify"
                path="/web/sendnotify"
                component={SendNotify}
                layout={MemberLayout}
            />
            <RouteWithLayout
                exact
                privatePage
                label="Users"
                path="/web/user-management"
                component={UserManagement}
                locked={lockMode}
                layout={MemberLayout}
            />
            <RouteWithLayout
                exact
                privatePage
                label="Groups"
                path="/web/group-management"
                component={GroupManagement}
                locked={lockMode}
                layout={MemberLayout}
            />
            <RouteWithLayout
                exact
                privatePage
                label="Contact"
                path="/web/contact"
                component={Contact}
                locked={lockMode}
                layout={MemberLayout}
            />
            <RouteWithLayout
                exact
                privatePage
                label="Organisation"
                path="/web/organisation"
                component={Organisation}
                locked={lockMode}
                layout={MemberLayout}
            />
            <RouteWithLayout
                exact
                privatePage
                label="Group"
                path="/web/group"
                component={Group}
                locked={lockMode}
                layout={MemberLayout}
            />
            <RouteWithLayout component={BaseRoutes} layout={MemberLayout} />
        </Switch>
    );
};
