/** @format */

const axios = require('axios');
const {MYCORP_NOTIFY_ENDPOINTS} = require('../tools/Constants');

export const registerFirebase = async () => {
    try {
        const token = localStorage.getItem('@mycorp-firebase-token');
        const {email, system, account} = JSON.parse(localStorage.getItem('@mycorp_principal') || {});

        if (email && system && account) {
            const res = await axios.post(
                `${MYCORP_NOTIFY_ENDPOINTS}/api/firebase/register`,
                {
                    email,
                    token,
                    system,
                    account,
                },
                {}
            );

            return res;
        }
    } catch (e) {
        return Promise.reject('Credentials not valid');
    }
};
