/** @format */

import React, {useState} from 'react';
import styled from 'styled-components';

import {Radio as MRadio, RadioGroup as MRadioGroup, Typography} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const Radio = (props) => {
    const {id, group} = props;
    if (group) return <RadioGroup {...props} />;
    return <FormControlLabel control={<MRadio id={id} name={id} {...props} />} {...props} />;
};

const RadioGroup = (props) => {
    const {onChange, value, title, groupId, options} = props;

    return (
        <FormControl component="fieldset">
            {title && (
                <FormLabel component="legend">
                    <Label variant="h6">{title}</Label>
                </FormLabel>
            )}
            <MRadioGroup aria-label={groupId} name={groupId} value={value} onChange={onChange}>
                {options.map((x) => {
                    return (
                        <FormControlLabel
                            key={x.value}
                            value={x.value}
                            label={x.label}
                            control={<MRadio id={x.value} name={x.value} {...x} />}
                        />
                    );
                })}
            </MRadioGroup>
        </FormControl>
    );
};

const Label = styled(Typography)`
    padding: 10px 0;
`;

export default Radio;
