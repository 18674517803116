/** @format */

import FaceIcon from '@material-ui/icons/Face';
import EmailIcon from '@material-ui/icons/Email';
import FolderIcon from '@material-ui/icons/Folder';
import AssignmentIcon from '@material-ui/icons/Assignment';
import MessageIcon from '@material-ui/icons/Message';
import SettingsIcon from '@material-ui/icons/Settings';
import AboutIcon from '@material-ui/icons/Feedback';
import HomeIcon from '@material-ui/icons/Home';
import PaymentIcon from '@material-ui/icons/Payment';
import TimerIcon from '@material-ui/icons/Timer';
import HelpIcon from '@material-ui/icons/Help';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import ListAltIcon from '@material-ui/icons/ListAlt';
import TimelineIcon from '@material-ui/icons/Timeline';
import ContactsIcon from '@material-ui/icons/Contacts';

export const getMemberMenus = (accountInfo, userInfo) => {
    const modules = (accountInfo && accountInfo.modules) || [];
    const {lockMode, token, admin} = userInfo || {};

    return [
        {
            label: 'Home',
            icon: HomeIcon,
            dashboard: false,
            menu: true,
            link: '/web/dashboard/home',
            bgColor: '#e91e63',
        },
        {
            label: 'Notification',
            icon: EmailIcon,
            secured: true,
            link: '/web/notify',
            bgColor: '#e91e63',
            dashboard: modules.includes('InstaNotify'),
            menu: modules.includes('InstaNotify'),
            analytics: false,
        },
        {
            label: 'Authenticator',
            secured: true,
            icon: FaceIcon,
            link: '/web/authenticate',
            bgColor: '#e91e63',
            dashboard: modules.includes('InstaSecure'),
            menu: modules.includes('InstaSecure'),
            analytics: false,
        },
        {
            label: 'ApprovalReq',
            secured: true,
            icon: FolderIcon,
            link: '/web/instaapv',
            bgColor: '#e91e63',
            dashboard: modules.includes('InstaAPV'),
            menu: modules.includes('InstaAPV'),
            analytics: false,
        },
        {
            label: 'Todo',
            icon: AssignmentIcon,
            secured: true,
            link: '/web/instatask',
            bgColor: '#e91e63',
            dashboard: modules.includes('InstaTask'),
            menu: modules.includes('InstaTask'),
            analytics: false,
        },
        {
            label: 'Expense',
            secured: true,
            icon: PaymentIcon,
            link: '/web/expenses',
            bgColor: '#e91e63',
            dashboard: modules.includes('InstaXpense'),
            menu: modules.includes('InstaXpense'),
            analytics: true,
        },
        {
            label: 'Timesheet',
            secured: true,
            icon: TimerIcon,
            link: '/web/timesheet',
            bgColor: '#e91e63',
            dashboard: modules.includes('InstaTime'),
            menu: modules.includes('InstaTime'),
            analytics: true,
            analyticsLink: '/web/timesheet/analytics',
        },
        {
            // Timesheet analytics is a hidden page, accessible only from within Analytics page
            label: 'Analytics', // Label it as Analytics, however this page is actually Timesheet Analytics
            secured: true,
            icon: TimerIcon,
            link: '/web/timesheet/analytics',
            bgColor: '#e91e63',
            dashboard: false,
            menu: false,
            hide: true,
        },
        {
            label: 'Leave',
            secured: true,
            icon: FlightTakeoffIcon,
            link: '/web/leave',
            bgColor: '#e91e63',
            dashboard: modules.includes('InstaLeave'),
            menu: modules.includes('InstaLeave'),
            analytics: true,
        },
        {
            label: 'Survey',
            secured: true,
            icon: ListAltIcon,
            link: '/web/survey',
            bgColor: '#e91e63',
            dashboard: modules.includes('InstaSurvey'),
            menu: modules.includes('InstaSurvey'),
            analytics: false,
        },
        {
            label: 'Contact',
            secured: true,
            icon: ContactsIcon,
            link: '/web/contact',
            bgColor: '#e91e63',
            dashboard: modules.includes('InstaContact'),
            menu: modules.includes('InstaContact'),
            analytics: false,
        },
        {
            label: 'Analytics',
            secured: true,
            icon: TimelineIcon,
            link: '/web/analytics',
            bgColor: '#e91e63',
            dashboard: modules.includes('InstaAnalytics'),
            menu: modules.includes('InstaAnalytics'),
            analytics: false,
        },
        {
            label: 'Organisation',
            secured: true,
            icon: ContactsIcon,
            link: '/web/organisation',
            bgColor: '#e91e63',
            dashboard: false,
            menu: false,
            hide: true,
            analytics: false,
        },
        // {
        //     label: 'Apps',
        //     icon: VpnKeyIcon,
        //     link: '/web/dashboard/lock',
        //     bgColor: '#e91e63',
        //     dashboard: lockMode,
        //     menu: lockMode,
        // },
    ];
};

export const getOtherMenus = (accountInfo, userInfo) => {
    const {modules} = accountInfo || {};
    const {email, admin} = userInfo || {};

    return [
        {
            label: 'Settings',
            icon: SettingsIcon,
            link: '/web/settings',
            bgColor: '#448aff',
            dashboard: false,
            menu: true,
        },
        {
            label: 'Users',
            icon: SettingsIcon,
            link: '/web/user-management',
            bgColor: '#448aff',
            dashboard: admin != null && admin,
            menu: admin != null && admin,
        },
        // {
        //     label: 'Messaging',
        //     icon: MessageIcon,
        //     link: '/web/sendnotify',
        //     bgColor: '#448aff',
        //     dashboard: admin != null && admin,
        //     menu: admin != null && admin,
        // },
        {
            label: 'About',
            icon: AboutIcon,
            link: '/web/about',
            bgColor: '#ffb944',
            dashboard: false,
            menu: true,
        },
        {
            label: 'Help',
            icon: HelpIcon,
            link: '/web/feedback',
            bgColor: '#ffb944',
            dashboard: false,
            menu: true,
        },
    ];
};
