/** @format */

import React, {useEffect, useCallback, useState, createContext} from 'react';
import {useHistory} from 'react-router-dom';

import {myCorpAuthSocket} from 'sockets/Auth/Auth';
import {useForceUpdate} from 'tools/CustomHooks';

export const AuthContext = createContext({});

export const AuthProvider = (props) => {
    const [loading, setLoading] = useState(false);
    const [isLogin, setIsLogin] = useState(localStorage.getItem('@mycorp_id') ? true : false);
    const forceUpdate = useForceUpdate();

    const history = useHistory();
    const {pathname} = window.location || {pathname: ''};

    const getToken = useCallback(() => {
        const id = localStorage.getItem('@mycorp_id');
        return id;
    }, []);

    const getUsersByAccount = useCallback(async (property) => {
        try {
            let userInfo = JSON.parse(localStorage.getItem('@mycorp_principal'));
            setLoading(true);
            const res = await myCorpAuthSocket.getUsersByAccount(userInfo.account);
            return res;
        } catch (e) {
            console.log('e: ', e);
            throw e;
        } finally {
            setLoading(false);
        }
    }, []);

    const getGroupsByAccount = useCallback(async (property) => {
        try {
            let userInfo = JSON.parse(localStorage.getItem('@mycorp_principal'));
            setLoading(true);
            const res = await myCorpAuthSocket.getGroupsByAccount(userInfo.account);
            return res;
        } catch (e) {
            console.log('e: ', e);
            throw e;
        } finally {
            setLoading(false);
        }
    }, []);

    const getUserInfo = useCallback((property) => {
        let userInfo = localStorage.getItem('@mycorp_principal');
        userInfo = userInfo ? JSON.parse(userInfo) : {};
        return property ? userInfo[property] : userInfo;
    }, []);

    const getAccountInfo = useCallback((property) => {
        let accountInfo = localStorage.getItem('@mycorp_account');
        accountInfo = accountInfo ? JSON.parse(accountInfo) : {};
        return property ? accountInfo[property] : accountInfo;
    }, []);

    const setAccountStorage = useCallback((account) => {
        if (!account) return;
        localStorage.setItem('@mycorp_account', JSON.stringify(account));
        forceUpdate();
    }, []);

    const setPricipalStorage = useCallback((userInfo) => {
        if (!userInfo) return;
        localStorage.setItem('@mycorp_principal', JSON.stringify(userInfo));
        forceUpdate();
    }, []);

    const setIdStorage = useCallback((id) => {
        if (!id) return;
        localStorage.setItem('@mycorp_id', id);
        forceUpdate();
    }, []);

    const clearToken = useCallback(async () => {
        try {
            await myCorpAuthSocket.clearToken(localStorage.getItem('@mycorp-firebase-token'));
        } catch (e) {
            console.log(e);
        }
    }, []);

    const forceSignOut = useCallback(async (email, system, account) => {
        baseSignOut(email, system, account, true, localStorage.getItem('@mycorp-firebase-token'));
    }, []);

    const signOut = useCallback(async (email, system, account) => {
        baseSignOut(email, system, account, false, null);
    }, []);

    const baseSignOut = async (email, system, account, isForced, currentFirebaseToken) => {
        setLoading(true);

        try {
            if (isForced && currentFirebaseToken) {
                await myCorpAuthSocket.clearToken(currentFirebaseToken);
            } else {
                await myCorpAuthSocket.logout(email, system, account);
            }
        } catch (e) {
            console.log(e);
        }

        localStorage.removeItem('@mycorp_principal');
        localStorage.removeItem('@mycorp_account');
        localStorage.removeItem('@mycorp_id');
        localStorage.removeItem('@mycorp-firebase-token');

        setIsLogin(false);
        setLoading(false);

        // web only
        if (pathname.includes('web')) {
            window.location.href = '/web/login';
        }
    };

    const login = useCallback(async (email, password, system, account) => {
        try {
            setLoading(true);
            const loginRes = await myCorpAuthSocket.login(email, password, system, account);
            setPricipalStorage(loginRes);
            setIdStorage(loginRes && loginRes.email);
            setIsLogin(true);
            // This is to logout the rest of the open session
            await myCorpAuthSocket.logout(email, system, account);
            return loginRes;
        } catch (e) {
            console.log('e: ', e);
            throw e;
        } finally {
            setLoading(false);
        }
    }, []);

    const loginCheck = useCallback(async (email, password, system, account) => {
        try {
            setLoading(true);
            const loginRes = await myCorpAuthSocket.loginCheck(email, password, system, account);
            return loginRes;
        } catch (e) {
            console.log('e: ', e);
            throw e;
        } finally {
            setLoading(false);
        }
    }, []);

    const loginConfirm = useCallback(async (loginRes) => {
        try {
            const {email, sessionId, sessionUserAgent, system, account} = loginRes;
            setPricipalStorage(loginRes);
            setIdStorage(loginRes && loginRes.email);
            setIsLogin(true);
            // This is to update my current login
            await myCorpAuthSocket.updateLastLogin(email, sessionId, sessionUserAgent);
            // This is to logout the rest of the open session
            await myCorpAuthSocket.logout(email, system, account);
            return loginRes;
        } catch (e) {
            console.log('e: ', e);
            throw e;
        } finally {
            setLoading(false);
        }
    }, []);

    const sendOTPToken = useCallback(async (email, phone, account, system) => {
        try {
            setLoading(true);
            const loginRes = await myCorpAuthSocket.sendOTPToken(email, phone, account, system);
            return loginRes;
        } catch (e) {
            console.log('e: ', e);
            throw e;
        } finally {
            setLoading(false);
        }
    }, []);

    const verifyOTPToken = useCallback(async (email, otpToken, account, system) => {
        try {
            setLoading(true);
            const loginRes = await myCorpAuthSocket.verifyOTPToken(email, otpToken, account, system);
            return loginRes;
        } catch (e) {
            console.log('e: ', e);
            throw e;
        } finally {
            setLoading(false);
        }
    }, []);

    const createUser = useCallback(async (payload) => {
        try {
            setLoading(true);
            const accountRes = await myCorpAuthSocket.createUser(payload);
            return accountRes;
        } catch (e) {
            console.log('e: ', e);
            throw e;
        } finally {
            setLoading(false);
        }
    }, []);

    const getGroupsByEmail = useCallback(async (payload) => {
        try {
            setLoading(true);
            let userInfo = JSON.parse(localStorage.getItem('@mycorp_principal'));
            const res = await myCorpAuthSocket.getGroupsByEmail(payload, userInfo.account);
            return res;
        } catch (e) {
            console.log('e: ', e);
            throw e;
        } finally {
            setLoading(false);
        }
    }, []);

    const getUsersByGroupCode = useCallback(async (payload) => {
        try {
            setLoading(true);
            let userInfo = JSON.parse(localStorage.getItem('@mycorp_principal'));
            const res = await myCorpAuthSocket.getUsersByGroupCode(payload, userInfo.account);
            return res;
        } catch (e) {
            console.log('e: ', e);
            throw e;
        } finally {
            setLoading(false);
        }
    }, []);

    const updateUserGroupByEmail = useCallback(async (email, userGroups) => {
        try {
            setLoading(true);
            let userInfo = JSON.parse(localStorage.getItem('@mycorp_principal'));
            const res = await myCorpAuthSocket.updateUserGroupByEmail(email, userGroups, userInfo.account);
            return res;
        } catch (e) {
            console.log('e: ', e);
            throw e;
        } finally {
            setLoading(false);
        }
    }, []);

    const updateUserGroupByGroupCode = useCallback(async (code, userGroups) => {
        try {
            setLoading(true);
            let userInfo = JSON.parse(localStorage.getItem('@mycorp_principal'));
            const res = await myCorpAuthSocket.updateUserGroupByGroupCode(code, userGroups, userInfo.account);
            return res;
        } catch (e) {
            console.log('e: ', e);
            throw e;
        } finally {
            setLoading(false);
        }
    }, []);

    const createGroup = useCallback(async (payload) => {
        try {
            setLoading(true);
            const accountRes = await myCorpAuthSocket.createGroup(payload);
            return accountRes;
        } catch (e) {
            console.log('e: ', e);
            throw e;
        } finally {
            setLoading(false);
        }
    }, []);

    const registerAccount = useCallback(async (values) => {
        try {
            setLoading(true);
            const accountRes = await myCorpAuthSocket.registerAccount(values);
            return accountRes;
        } catch (e) {
            console.log('e: ', e);
            throw e;
        } finally {
            setLoading(false);
        }
    }, []);

    const doesAccountExist = useCallback(async (account) => {
        try {
            setLoading(true);
            const res = await myCorpAuthSocket.doesAccountExist(account);
            return res;
        } catch (e) {
            console.log('e: ', e);
            throw e;
        } finally {
            setLoading(false);
        }
    }, []);

    const doesGroupCodeExist = useCallback(async (code) => {
        try {
            setLoading(true);
            let userInfo = JSON.parse(localStorage.getItem('@mycorp_principal'));
            const res = await myCorpAuthSocket.doesGroupCodeExist(code, userInfo.account);
            return res;
        } catch (e) {
            console.log('e: ', e);
            throw e;
        } finally {
            setLoading(false);
        }
    }, []);

    const doesEmailExist = useCallback(async (email) => {
        try {
            setLoading(true);
            const res = await myCorpAuthSocket.doesEmailExist(email);
            return res;
        } catch (e) {
            console.log('e: ', e);
            throw e;
        } finally {
            setLoading(false);
        }
    }, []);

    const doesPhoneExist = useCallback(async (phone) => {
        try {
            setLoading(true);
            const res = await myCorpAuthSocket.doesPhoneExist(phone);
            return res;
        } catch (e) {
            console.log('e: ', e);
            throw e;
        } finally {
            setLoading(false);
        }
    }, []);

    const findUser = useCallback(async (email) => {
        try {
            setLoading(true);
            const res = await myCorpAuthSocket.findUser(email);
            const accountRes = await myCorpAuthSocket.findAccounts(res.account, res.system);
            setPricipalStorage(res);
            setAccountStorage(accountRes);
            return res;
        } catch (e) {
            console.log('e: ', e);
            throw e;
        } finally {
            setLoading(false);
        }
    }, []);

    const findUserByPhone = useCallback(async (phone) => {
        try {
            setLoading(true);
            const res = await myCorpAuthSocket.findUserByPhone(phone);
            const accountRes = await myCorpAuthSocket.findAccounts(res.account, res.system);
            setPricipalStorage(res);
            setAccountStorage(accountRes);
            return res;
        } catch (e) {
            console.log('e: ', e);
            throw e;
        } finally {
            setLoading(false);
        }
    }, []);

    const findAccount = useCallback(async (account, system) => {
        try {
            setLoading(true);
            const res = await myCorpAuthSocket.findAccounts(account, system);
            setAccountStorage(res);
            return res;
        } catch (e) {
            console.log('e: ', e);
            throw e;
        } finally {
            setLoading(false);
        }
    }, []);

    const updateUser = useCallback(async (data) => {
        try {
            setLoading(true);
            const res = await myCorpAuthSocket.updateUser(data);
            return res;
        } catch (e) {
            console.log('e: ', e);
            throw e;
        } finally {
            setLoading(false);
        }
    }, []);

    const deleteUser = useCallback(async (email) => {
        try {
            setLoading(true);
            const res = await myCorpAuthSocket.deleteUser(email);
            return res;
        } catch (e) {
            console.log('e: ', e);
            throw e;
        } finally {
            setLoading(false);
        }
    }, []);

    const updateGroup = useCallback(async (data) => {
        try {
            setLoading(true);
            const res = await myCorpAuthSocket.updateGroup(data);
            return res;
        } catch (e) {
            console.log('e: ', e);
            throw e;
        } finally {
            setLoading(false);
        }
    }, []);

    const deleteGroup = useCallback(async (code) => {
        try {
            setLoading(true);
            let userInfo = JSON.parse(localStorage.getItem('@mycorp_principal'));
            const res = await myCorpAuthSocket.deleteGroup(code, userInfo.account);
            return res;
        } catch (e) {
            console.log('e: ', e);
            throw e;
        } finally {
            setLoading(false);
        }
    }, []);

    const mobileLogin = useCallback(async (token) => {
        try {
            setLoading(true);
            const res = await myCorpAuthSocket.decodeToken(token);
            setIsLogin(true);
            setIdStorage(res && res.email);
            return res;
        } catch (e) {
            console.log('e: ', e);
            throw e;
        } finally {
            setLoading(false);
        }
    }, []);

    const updateUserInfoListener = (filter) => {
        myCorpAuthSocket.updateUserInfoListener(filter);
    };

    const subscribeUserInfo = () => {
        const userInfo = getUserInfo();
        myCorpAuthSocket.subscribeUserInfo((userInfo) => setPricipalStorage(userInfo));

        setTimeout(() => {
            updateUserInfoListener({
                email: userInfo.email,
                account: userInfo.account,
                system: userInfo.system,
            });
        }, 2000);
    };

    useEffect(() => {
        if (!isLogin) return;
        subscribeUserInfo();
    }, [isLogin]);

    return (
        <AuthContext.Provider
            value={{
                getToken,
                getUserInfo,
                getAccountInfo,
                login,
                signOut,
                loading,
                clearToken,
                isLogin,
                forceSignOut,
                mobileLogin,
                findUser,
                findAccount,
                findUserByPhone,
                updateUser,
                deleteUser,
                updateGroup,
                deleteGroup,
                registerAccount,
                createUser,
                createGroup,
                doesEmailExist,
                doesPhoneExist,
                setAccountStorage,
                doesGroupCodeExist,
                doesAccountExist,
                loginCheck,
                loginConfirm,
                sendOTPToken,
                verifyOTPToken,
                getGroupsByAccount,
                getUsersByAccount,
                getGroupsByEmail,
                getUsersByGroupCode,
                updateUserGroupByEmail,
                updateUserGroupByGroupCode,
            }}
        >
            {props.children}
        </AuthContext.Provider>
    );
};
