/**
 * Authorise Socket
 *
 * @format
 */

import socketIOClient from 'socket.io-client';
const {MYCORP_AUTHORISE_ENDPOINTS} = require('tools/Constants');
const uuidv1 = require('uuid/v1');

class AuthoriseSocket {
    _socket = null;

    init(_id, password, token, account, system, module, onConnect) {
        this._socket = socketIOClient(MYCORP_AUTHORISE_ENDPOINTS, {
            path: '/authorise',
            rememberTransport: false,
            transports: ['websocket'],
            query: {
                _id,
                token,
                account,
                system,
                module,
                password,
            },
        });

        this._socket.on('socketInitialized', onConnect);
    }

    updateTasksListener = (filter) => {
        this._socket.emit('updateTasksListener', filter);
    };

    subscribeTasks = (callback) => {
        this._socket.on('tasks', (response) => {
            callback && callback(response);
        });
    };

    completeTask = (task) => {
        const correlationId = uuidv1();

        this._socket.emit('completeTask', {
            correlationId,
            task,
        });
    };

    clearInfo = () => {
        const correlationId = uuidv1();

        this._socket.emit('clearInfo', {
            correlationId,
        });
    };

    deleteInfo = () => {
        const correlationId = uuidv1();

        this._socket.emit('deleteInfo', {
            correlationId,
        });
    };
}

export const myCorpAuthoriseSocket = new AuthoriseSocket();
