/** @format */
import moment from 'moment';
import Base64 from 'sockets/base64';

export const generateHash = (pwd) => {
    const crypto = require('crypto');
    return crypto.createHash('sha256').update(pwd, 'utf8').digest('base64');
};

export const isInViewport = (el, type = 'part', increaseOffset = 0) => {
    if (!el) return false;
    const rect = el.getBoundingClientRect();

    let innerHeight = window.innerHeight || document.documentElement.clientHeight;
    innerHeight = innerHeight + innerHeight * increaseOffset;

    if (type === 'full') return rect.top >= 0 && rect.bottom <= innerHeight;
    if (type === 'part') return rect.bottom >= 0 && rect.top <= innerHeight;
};

export const hexToBase64 = (str) => {
    return Base64.btoa(
        String.fromCharCode.apply(
            null,
            str
                .replace(/\r|\n/g, '')
                .replace(/([\da-fA-F]{2}) ?/g, '0x$1 ')
                .replace(/ +$/, '')
                .split(' ')
        )
    );
};

export const getColor = (status = '', theme) => {
    switch (status.toString().toLowerCase()) {
        case '1':
        case 'confirmed':
        case 'accepted':
        case 'active':
        case 'successful':
        case 'approved':
        case 'true':
        case 'normal':
            return theme.palette.success.main;
        case 'pending':
        case 'queued':
        case 'in queued':
            return theme.palette.info.main;
        case '0':
        case 'false':
        case 'inactive':
        case 'denied':
        case 'cancelled':
        case 'rejected':
        case 'high':
            return theme.palette.error.main;
        default:
            return 'gray';
    }
};

export const hyperlinkDownload = (url, filename) => {
    let hyperlink = document.createElement('a');
    hyperlink.href = url;
    hyperlink.setAttribute('download', filename || '');
    hyperlink.setAttribute('target', '_blank');
    hyperlink.onclick = (e) => e.stopPropagation();
    hyperlink.click();
};

export const capitalize = (word) => {
    if (!word) return '';
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
};

export const formatDate = (from, pattern) => {
    if (!from || !pattern) return;
    return moment(from).format(pattern);
};

export const getMonthName = (month) => {
    if (!month) return;
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    return months[month - 1];
};

// Get weekday of given Date
export const getDay = (date) => {
    if (!date) return;
    return new Intl.DateTimeFormat('en-US', {weekday: 'long'}).format(date);
};

// Get date into DD MMM YYYY format
export const getDate = (date) => {
    if (!date) return;
    const month = date.toLocaleString('default', {month: 'long'});
    return `${date.getDate()} ${month} ${date.getFullYear()}`;
};

export const breakDate = (date) => {
    if (!date || !(date instanceof Date)) return {};
    const momentDate = moment(date);
    let result = {};

    try {
        result = {
            original: date,
            timestamp: date.getTime(),
            date: parseInt(momentDate.format('D')),
            month: parseInt(momentDate.format('M')),
            year: parseInt(momentDate.format('YYYY')),
            hour: parseInt(momentDate.format('H')),
            minute: parseInt(momentDate.format('m')),
            monthLong: getMonthName(parseInt(momentDate.format('M'))),
            weekday: getDay(date),
            dateString: getDate(date),
        };
    } finally {
        return result;
    }
};

export const isMobile = () => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true;
    }

    return false;
};
