/**
 * Contact Socket
 *
 * @format
 */

import socketIOClient from 'socket.io-client';
const {MYCORP_CONTACT_ENDPOINTS} = require('tools/Constants');
const uuidv1 = require('uuid/v1');

class MyCorpContactSocket {
    _socket = null;

    async init(token, account, system, module, onConnect) {
        this._socket = socketIOClient(MYCORP_CONTACT_ENDPOINTS, {
            path: '/contact',
            rememberTransport: false,
            transports: ['websocket'],
            query: {
                token,
                account,
                system,
                module,
            },
        });

        this._socket.on('socketInitialized', (response) => {
            onConnect();
        });
    }

    async stop() {
        if (this._socket) this._socket.disconnect();
    }

    getContacts() {
        const correlationId = uuidv1(); // correlationId is a required field, used for tracking the response from backend socket

        console.log('[' + correlationId + '] getContacts');

        this._socket.emit('getContacts', {
            correlationId,
        });
        const tempSocket = this._socket;
        return new Promise(function (resolve, reject) {
            tempSocket.on(correlationId, (response) => {
                resolve(response);
            });
        });
    }

    findContactByEmail(email) {
        const correlationId = uuidv1(); // correlationId is a required field, used for tracking the response from backend socket

        console.log('[' + correlationId + '] findContactByEmail');

        this._socket.emit('findContactByEmail', {
            correlationId,
            email,
        });
        const tempSocket = this._socket;
        return new Promise(function (resolve, reject) {
            tempSocket.on(correlationId, (response) => {
                resolve(response);
            });
        });
    }

    createContact(form) {
        const correlationId = uuidv1(); // correlationId is a required field, used for tracking the response from backend socket

        console.log('[' + correlationId + '] createContact');

        this._socket.emit('createContact', {correlationId, form});
        const tempSocket = this._socket;
        return new Promise(function (resolve, reject) {
            tempSocket.on(correlationId, (response) => {
                console.log('[' + correlationId + '] createContact, result: ', response);
                resolve(response);
            });
        });
    }

    updateContact(id, form) {
        const correlationId = uuidv1(); // correlationId is a required field, used for tracking the response from backend socket

        console.log('[' + correlationId + '] updateContact, id: ', id);

        this._socket.emit('updateContact', {correlationId, id, form});
        const tempSocket = this._socket;
        return new Promise(function (resolve, reject) {
            tempSocket.on(correlationId, (response) => {
                console.log('[' + correlationId + '] updateContact, result: ', response);
                resolve(response);
            });
        });
    }

    deleteContact(id) {
        const correlationId = uuidv1(); // correlationId is a required field, used for tracking the response from backend socket

        console.log('[' + correlationId + '] deleteContact');

        this._socket.emit('deleteContact', {
            correlationId,
            id,
        });
        const tempSocket = this._socket;
        return new Promise(function (resolve, reject) {
            tempSocket.on(correlationId, (response) => {
                console.log('[' + correlationId + '] deleteContact, result: ', response);
                resolve(response);
            });
        });
    }

    getOrganisations() {
        const correlationId = uuidv1(); // correlationId is a required field, used for tracking the response from backend socket

        console.log('[' + correlationId + '] getOrganisations');

        this._socket.emit('getOrganisations', {
            correlationId,
        });
        const tempSocket = this._socket;
        return new Promise(function (resolve, reject) {
            tempSocket.on(correlationId, (response) => {
                resolve(response);
            });
        });
    }

    findOrganisationByEmail(email) {
        const correlationId = uuidv1(); // correlationId is a required field, used for tracking the response from backend socket

        console.log('[' + correlationId + '] findOrganisationByEmail');

        this._socket.emit('findOrganisationByEmail', {
            correlationId,
            email,
        });
        const tempSocket = this._socket;
        return new Promise(function (resolve, reject) {
            tempSocket.on(correlationId, (response) => {
                resolve(response);
            });
        });
    }

    createOrganisation(form) {
        const correlationId = uuidv1(); // correlationId is a required field, used for tracking the response from backend socket

        console.log('[' + correlationId + '] createOrganisation');

        this._socket.emit('createOrganisation', {correlationId, form});
        const tempSocket = this._socket;
        return new Promise(function (resolve, reject) {
            tempSocket.on(correlationId, (response) => {
                console.log('[' + correlationId + '] createOrganisation, result: ', response);
                resolve(response);
            });
        });
    }

    updateOrganisation(id, form) {
        const correlationId = uuidv1(); // correlationId is a required field, used for tracking the response from backend socket

        console.log('[' + correlationId + '] updateOrganisation, id: ', id);

        this._socket.emit('updateOrganisation', {correlationId, id, form});
        const tempSocket = this._socket;
        return new Promise(function (resolve, reject) {
            tempSocket.on(correlationId, (response) => {
                console.log('[' + correlationId + '] updateOrganisation, result: ', response);
                resolve(response);
            });
        });
    }

    deleteOrganisation(id) {
        const correlationId = uuidv1(); // correlationId is a required field, used for tracking the response from backend socket

        console.log('[' + correlationId + '] deleteOrganisation');

        this._socket.emit('deleteOrganisation', {
            correlationId,
            id,
        });
        const tempSocket = this._socket;
        return new Promise(function (resolve, reject) {
            tempSocket.on(correlationId, (response) => {
                console.log('[' + correlationId + '] deleteOrganisation, result: ', response);
                resolve(response);
            });
        });
    }

    getGroups() {
        const correlationId = uuidv1(); // correlationId is a required field, used for tracking the response from backend socket

        console.log('[' + correlationId + '] getGroups');

        this._socket.emit('getGroups', {
            correlationId,
        });
        const tempSocket = this._socket;
        return new Promise(function (resolve, reject) {
            tempSocket.on(correlationId, (response) => {
                resolve(response);
            });
        });
    }

    findGroupByEmail(email) {
        const correlationId = uuidv1(); // correlationId is a required field, used for tracking the response from backend socket

        console.log('[' + correlationId + '] findGroupByEmail');

        this._socket.emit('findGroupByEmail', {
            correlationId,
            email,
        });
        const tempSocket = this._socket;
        return new Promise(function (resolve, reject) {
            tempSocket.on(correlationId, (response) => {
                resolve(response);
            });
        });
    }

    createGroup(form) {
        const correlationId = uuidv1(); // correlationId is a required field, used for tracking the response from backend socket

        console.log('[' + correlationId + '] createGroup');

        this._socket.emit('createGroup', {correlationId, form});
        const tempSocket = this._socket;
        return new Promise(function (resolve, reject) {
            tempSocket.on(correlationId, (response) => {
                console.log('[' + correlationId + '] createGroup, result: ', response);
                resolve(response);
            });
        });
    }

    updateGroup(id, form) {
        const correlationId = uuidv1(); // correlationId is a required field, used for tracking the response from backend socket

        console.log('[' + correlationId + '] updateGroup, id: ', id);

        this._socket.emit('updateGroup', {correlationId, id, form});
        const tempSocket = this._socket;
        return new Promise(function (resolve, reject) {
            tempSocket.on(correlationId, (response) => {
                console.log('[' + correlationId + '] updateGroup, result: ', response);
                resolve(response);
            });
        });
    }

    deleteGroup(id) {
        const correlationId = uuidv1(); // correlationId is a required field, used for tracking the response from backend socket

        console.log('[' + correlationId + '] deleteGroup');

        this._socket.emit('deleteGroup', {
            correlationId,
            id,
        });
        const tempSocket = this._socket;
        return new Promise(function (resolve, reject) {
            tempSocket.on(correlationId, (response) => {
                console.log('[' + correlationId + '] deleteGroup, result: ', response);
                resolve(response);
            });
        });
    }

    getGroupsByContactId(contactId) {
        const correlationId = uuidv1(); // correlationId is a required field, used for tracking the response from backend socket

        console.log('[' + correlationId + '] getGroupsByContactId, contactId: ', contactId);

        this._socket.emit('getGroupsByContactId', {correlationId, contactId});
        const tempSocket = this._socket;
        return new Promise(function (resolve, reject) {
            tempSocket.on(correlationId, (response) => {
                console.log('[' + correlationId + '] getGroupsByContactId, result: ', response);
                resolve(response);
            });
        });
    }

    getContactsByGroupId(groupId) {
        const correlationId = uuidv1(); // correlationId is a required field, used for tracking the response from backend socket

        console.log('[' + correlationId + '] getContactsByGroupId, groupId: ', groupId);

        this._socket.emit('getContactsByGroupId', {correlationId, groupId});
        const tempSocket = this._socket;
        return new Promise(function (resolve, reject) {
            tempSocket.on(correlationId, (response) => {
                console.log('[' + correlationId + '] getContactsByGroupId, result: ', response);
                resolve(response);
            });
        });
    }

    updateContactGroupByContactId(contactId, groupIds) {
        const correlationId = uuidv1(); // correlationId is a required field, used for tracking the response from backend socket

        console.log('[' + correlationId + '] updateContactGroupByContactId, contactId: ', contactId);

        this._socket.emit('updateContactGroupByContactId', {correlationId, contactId, groupIds});
        const tempSocket = this._socket;
        return new Promise(function (resolve, reject) {
            tempSocket.on(correlationId, (response) => {
                console.log('[' + correlationId + '] updateContactGroupByContactId, result: ', response);
                resolve(response);
            });
        });
    }

    updateContactGroupByGroupId(groupId, contactIds) {
        const correlationId = uuidv1(); // correlationId is a required field, used for tracking the response from backend socket

        console.log('[' + correlationId + '] updateContactGroupByGroupId, groupId: ', groupId);

        this._socket.emit('updateContactGroupByGroupId', {correlationId, groupId, contactIds});
        const tempSocket = this._socket;
        return new Promise(function (resolve, reject) {
            tempSocket.on(correlationId, (response) => {
                console.log('[' + correlationId + '] v, result: ', response);
                resolve(response);
            });
        });
    }
}

const myCorpContactSocket = new MyCorpContactSocket();
export {myCorpContactSocket};
