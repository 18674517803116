/** @format */

import React from 'react';

import {Avatar as MAvatar, Grid, Typography} from '@material-ui/core';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import {Tooltip} from 'components';

import {useStyles, Wrapper, AvatarRow} from './Avatar.style';

export const Avatar = (props) => {
    const {group, attachments, max = 4, type = 's', onClick} = props;
    const classes = useStyles();

    const avatarComp = (!attachments.length ? [attachments] : attachments).map((x, i) => {
        const {alt, src, label} = x;

        return (
            <AvatarRow onClick={onClick} key={i}>
                <Tooltip label={alt || ''}>
                    <MAvatar className={classes[type]} alt={alt} src={src}>
                        {(alt || '').charAt(0).toUpperCase()}
                    </MAvatar>
                </Tooltip>
                {label && (
                    <Typography className={classes.label} variant="subtitle2">
                        {label}
                    </Typography>
                )}
            </AvatarRow>
        );
    });

    if (group) {
        return (
            <Wrapper>
                <AvatarGroup max={max}>{avatarComp}</AvatarGroup>
            </Wrapper>
        );
    }

    return <Wrapper>{avatarComp}</Wrapper>;
};
