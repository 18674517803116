/** @format */

import {makeStyles} from '@material-ui/styles';
import styled from 'styled-components';

import IconButton from '@material-ui/core/IconButton';

export const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        backgroundColor: theme.palette.background.primary,
    },
    otherMenusWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    envText: {
        color: theme.palette.primary.main,
    },
}));

export const ContentWrapper = styled.div`
    background-color: ${(props) => props.theme.palette.background.secondary};
    height: 100vh;
    overflow: scroll;
    padding: 30px;

    ${(props) => props.theme.breakpoints.down('md')} {
        padding: 20px 15px;
    },
`;

export const ContentWrapperIFrame = styled.div`
    background-color: ${(props) => props.theme.palette.background.secondary};
    height: 100vh;
    overflow: scroll;
    padding-top: 20px;
    padding-bottom: 0px;
    padding-left: 15px;
    padding-right: 15px;
`;

export const ContentChildren = styled.div`
    ${(props) => props.theme.breakpoints.down('sm')} {
        padding-bottom: 50px;
    }
    ${(props) => props.theme.breakpoints.down('xs')} {
        padding-bottom: 0px;
    }
`;

export const ContentChildrenFullHeight = styled.div`
    ${(props) => props.theme.breakpoints.down('sm')} {
        height: calc(100% - 50px);
    }
`;

export const ContentGrid = styled.div`
    ${(props) => props.theme.breakpoints.down('xl')} {
        width: ${(props) => (props.show ? '85%' : '100%')};
    }

    ${(props) => props.theme.breakpoints.down('lg')} {
        width: ${(props) => (props.show ? '80%' : '100%')};
    }

    ${(props) => props.theme.breakpoints.down('md')} {
        width: 100%;
        height: 100%;
    }

    transition: all 0.3s ease-in-out;
`;

export const SidebarGrid = styled.div`
    ${(props) => props.theme.breakpoints.down('xl')} {
        width: ${(props) => (props.show ? '15%' : '0%')};
    }

    ${(props) => props.theme.breakpoints.down('lg')} {
        width: ${(props) => (props.show ? '20%' : '0%')};
    }

    ${(props) => props.theme.breakpoints.down('md')} {
        overflow: scroll;
        width: 100%;
        height: ${(props) => (props.show ? '80%' : '0%')};
    }

    transition: all 0.3s ease-in-out;
    background-image: linear-gradient(
        to right,
        ${(props) => props.theme.palette.background.primary},
        ${(props) => props.theme.palette.background.secondary}
    );

    * {
        opacity: ${(props) => !props.show && '0'};
        transition: ${(props) => !props.show && 'all 0.15s ease-in-out'};
    }
`;

export const SidebarWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;

    padding: 20px;
`;

export const MenusWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const MainMenusWrapper = styled.div`
    height: 93%;

    ${(props) => props.theme.breakpoints.down('md')} {
        height: unset;
    }
`;

export const StyledMenu = styled.div`
    margin: 10px 0;
    padding: 15px;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-self: center;

    svg {
        margin-right: 15px;
        color: ${(props) => props.theme.palette.text.secondary};
    }

    h6 {
        align-self: center;
    }

    &:hover,
    &.active {
        box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 2px 6px 2px rgba(60, 64, 67, 0.15);
        background-image: linear-gradient(
            to bottom right,
            ${(props) => props.theme.palette.primary.main},
            ${(props) => props.theme.palette.secondary.main}
        );

        * {
            color: ${(props) => props.theme.palette.primary.contrastText};
            font-weight: 900;
        }
    }
`;

export const MenuButton = styled(IconButton)`
    padding: 10px;
`;

export const OtherSettingButton = styled(IconButton)`
    padding: 10px;
`;

export const UserInfo = styled.div`
    width: 100%;
    padding: 15px;

    .info {
        padding: 0 20px;
    }
`;

export const PageHeader = styled.span`
    padding: 20px 10px;
    font-weight: 900;

    h5 {
        display: inline;
    }
`;
