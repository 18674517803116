/** @format */

import React, {useState, useEffect} from 'react';
import {isMobile} from 'tools/Utils';

export const useForceUpdate = () => {
    const [value, setValue] = useState(0);
    const forceUpdate = () => setValue((value) => ++value);
    return forceUpdate;
};

export const useResize = () => {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener('resize', () => setWidth(window.innerWidth));
        return () => window.removeEventListener('resize', null);
    });

    return width;
};

export const useKeyPress = (targetKey) => {
    const [keyPressed, setKeyPressed] = useState(false);

    const downHandler = (e) => {
        const {key} = e;
        const {localName} = e.srcElement;
        if (key === targetKey && localName !== 'input') setKeyPressed(true);
    };

    const upHandler = (e) => {
        const {key} = e;
        const {localName} = e.srcElement;
        if (key === targetKey && localName !== 'input') setKeyPressed(false);
    };

    useEffect(() => {
        if (isMobile()) return;
        window.addEventListener('keydown', downHandler);
        window.addEventListener('keyup', upHandler);

        return () => {
            window.removeEventListener('keydown', downHandler);
            window.removeEventListener('keyup', upHandler);
        };
    }, []);

    return keyPressed;
};
