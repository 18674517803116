/** @format */

const axios = require('axios');
const {MYCORP_AUTH_ENDPOINTS} = require('../tools/Constants');

export const getLatestVersion = async () => {
    try {
        const res = await axios.get(`${MYCORP_AUTH_ENDPOINTS}/api/version/current?system=mycorp`);
        return res.data;
    } catch (e) {
        return e;
    }
};
