/** @format */

import React, {useMemo, createContext, useState, useContext, useEffect, useRef, forwardRef} from 'react';
import {useHistory} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {AuthContext} from 'contexts/Auth';

import {Grid, Typography, Menu as MuiMenu, MenuItem} from '@material-ui/core';
import {
    useStyles,
    ContentWrapper,
    ContentWrapperIFrame,
    SidebarWrapper,
    MenusWrapper,
    MainMenusWrapper,
    StyledMenu,
    UserInfo,
    MenuButton,
    OtherSettingButton,
    SidebarGrid,
    ContentGrid,
    PageHeader,
    ContentChildren,
    ContentChildrenFullHeight,
} from './Member.style';
import {Avatar, ButtonTypes, Dialog} from 'components';
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import {ThemeContext} from 'theme';
import {LockModeContext} from 'contexts/LockMode';

import {downloadFile} from 'api/DocApproval';
import {MODE} from 'tools/Constants';
import {useResize, useKeyPress} from 'tools/CustomHooks';
import {getMemberMenus, getOtherMenus} from './Member.menus';

export const SidebarContext = createContext({});
const isWeb = window.location.pathname.includes('web');

const Member = (props) => {
    const {children} = props;
    const [showMenu, setShowMenu] = useState(false);
    const [active, setActive] = useState('');

    const sidebarRef = useRef();
    const menuBtnRef = useRef();
    const classes = useStyles();
    const width = useResize();

    const {theme} = useContext(ThemeContext);
    const {getUserInfo, getAccountInfo} = useContext(AuthContext);
    const {lockModeVerified} = useContext(LockModeContext);

    const isSmall = width <= theme.breakpoints.values['lg'];
    const userInfo = getUserInfo();
    const {lockMode} = getUserInfo();

    const menus = getMemberMenus(getAccountInfo(), userInfo);

    const filterMenus = () => {
        let blackListMenus = [];
        if (lockMode && !lockModeVerified)
            blackListMenus = [
                'InstaAPV',
                'InstaTask',
                'InstaXpense',
                'InstaSurvey',
                'InstaXpense',
                'InstaTime',
                'InstaLeave',
                'InstaContact',
            ];
        if (lockMode && lockModeVerified) blackListMenus = ['Apps'];
        const filteredMenus = menus.filter((x) => !blackListMenus.includes(x.label));
        return filteredMenus;
    };

    return (
        <div className={classes.root}>
            <Grid container direction="row" className={classes.root}>
                <Content
                    {...props}
                    menus={filterMenus()}
                    active={active}
                    showMenu={showMenu}
                    ref={menuBtnRef}
                    show={showMenu}
                >
                    <SidebarContext.Provider
                        value={{
                            setShowMenu,
                            showMenu,
                            isSmall,
                            isWeb,
                            setActive,
                            menus: filterMenus(),
                        }}
                    >
                        {children}
                    </SidebarContext.Provider>
                </Content>
            </Grid>
        </div>
    );
};

const Menu = (props) => {
    const {active, label, icon: Icon, onClick, disabled} = props;
    if (disabled) return null;
    return (
        <StyledMenu onClick={onClick} className={clsx({active})}>
            {Icon && <Icon />}
            <Typography variant="h6">{label}</Typography>
        </StyledMenu>
    );
};

const Content = forwardRef((props, ref) => {
    const {active, menus, showMenu, children, onMenuClick, show, description, iframe} = props;

    const [showDialog, setShowDialog] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const classes = useStyles();
    const history = useHistory();
    const onKeyPress = useKeyPress('s');
    const {signOut, getAccountInfo, getUserInfo} = useContext(AuthContext);
    const userInfo = getUserInfo();

    const {pathname} = window.location;
    const {label} = menus.find((menu) => menu.link === pathname) || {};

    const {icon: Icon} = useMemo(() => menus.find((x) => x.label === active) || {}, [active]);
    const otherMenus = getOtherMenus(getAccountInfo(), getUserInfo());

    useEffect(() => {
        if (onKeyPress) onMenuClick();
    }, [onKeyPress]);

    const ContentWrapperComp = iframe ? ContentWrapperIFrame : ContentWrapper;
    return (
        <ContentGrid show={show}>
            <Dialog
                open={showDialog}
                onPositive={(e) => {
                    signOut(userInfo.email, userInfo.system, userInfo.account);
                }}
                onNegative={() => setShowDialog(false)}
                title="Are you sure you want to sign out?"
                remarks="You will be redirected to login page upon sign out"
            />
            <ContentWrapperComp show={show} id="content">
                <Grid container direction="row" alignItems="center">
                    <Grid item xs={9}>
                        <MenuButton
                            ref={ref}
                            variant={ButtonTypes.CONTAINED}
                            onClick={(e) => {
                                history.replace('/web/dashboard/home');
                            }}
                        >
                            <HomeIcon />
                        </MenuButton>
                        <PageHeader>
                            <Typography variant="h5" className="page-header">
                                {label && <>{label} </>}
                                {MODE === 'DEV' && (
                                    <Typography display="inline" className={classes.envText}>
                                        <b>TEST</b>
                                    </Typography>
                                )}
                            </Typography>
                            {description && (
                                <Typography variant="subtitle2" className="page-subheader">
                                    {description}
                                </Typography>
                            )}
                        </PageHeader>
                    </Grid>
                    <Grid item xs={3} className={classes.otherMenusWrapper}>
                        <OtherSettingButton
                            variant={ButtonTypes.CONTAINED}
                            onClick={(e) => setAnchorEl(e.currentTarget)}
                        >
                            <MoreVertIcon />
                        </OtherSettingButton>
                        <MuiMenu
                            id="simple-menu"
                            keepMounted
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={() => setAnchorEl(null)}
                        >
                            {otherMenus.map((x) => {
                                const {link, label, menu} = x;
                                if (!menu) return null;
                                return (
                                    <MenuItem
                                        key={label}
                                        onClick={() => {
                                            setAnchorEl(null);
                                            history.push(link);
                                        }}
                                    >
                                        {label}
                                    </MenuItem>
                                );
                            })}
                            <MenuItem
                                onClick={() => {
                                    setAnchorEl(null);
                                    setShowDialog(true);
                                }}
                            >
                                Logout
                            </MenuItem>
                        </MuiMenu>
                    </Grid>
                </Grid>
                {iframe ? (
                    <ContentChildrenFullHeight iframe>{children}</ContentChildrenFullHeight>
                ) : (
                    <ContentChildren iframe>{children}</ContentChildren>
                )}
            </ContentWrapperComp>
        </ContentGrid>
    );
});

const Sidebar = forwardRef((props, ref) => {
    const {menus, show, onMenuClick, active, setActive} = props;
    const [showDialog, setShowDialog] = useState(false);

    const history = useHistory();
    const {getUserInfo, signOut} = useContext(AuthContext);
    const userInfo = getUserInfo();
    const {fullname, account, profilepicture} = userInfo;

    const onClick = (menu) => {
        const {label, link, outerlink} = menu;
        setActive(label);
        if (link) history.push(link);
        if (outerlink) window.open(outerlink, '', 'location=no,toolbar=no,menubar=no,scrollbars=yes,resizable=yes');
        onMenuClick && onMenuClick(menu);
    };

    useEffect(() => {
        const {pathname} = window.location;
        const {label} = menus.find((menu) => menu.link === pathname) || {};
        setActive(label);
    }, [menus]);

    return (
        <SidebarGrid ref={ref} show={show} id="sidebar">
            <Dialog
                open={showDialog}
                onNegative={() => setShowDialog(false)}
                onPositive={(e) => {
                    signOut(userInfo.email, userInfo.system, userInfo.account);
                }}
                title="Are you sure you want to sign out?"
                remarks="You will be redirected to login page upon sign out"
            />
            <SidebarWrapper>
                <UserInfo>
                    <Grid container direction="row" alignItems="center">
                        <Avatar
                            type="large"
                            onClick={() => history.push(isWeb ? '/web/settings' : '/settings')}
                            attachments={[
                                {
                                    src: downloadFile(profilepicture),
                                    alt: '',
                                },
                            ]}
                        />
                        <Grid item className="info">
                            <Typography variant="subtitle2">{account && account.toUpperCase()}</Typography>
                            <Typography variant="h4">{fullname}</Typography>
                        </Grid>
                    </Grid>
                </UserInfo>
                <MenusWrapper>
                    <MainMenusWrapper>
                        {menus.map((menuItem) => {
                            const {label, menu} = menuItem;
                            if (!menu) {
                                return null;
                            }
                            return (
                                <Menu
                                    key={label}
                                    {...menuItem}
                                    active={active === label}
                                    onClick={() => onClick(menuItem)}
                                />
                            );
                        })}
                    </MainMenusWrapper>
                </MenusWrapper>
            </SidebarWrapper>
        </SidebarGrid>
    );
});

Member.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};

export default Member;
