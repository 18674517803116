/** @format */

const axios = require('axios');
const request = require('request');
const {MYCORP_FILE_API_URL} = require('../tools/Constants');

export const uploadFile = async (data) => {
    try {
        const res = await axios.post(`${MYCORP_FILE_API_URL}/api/file/upload`, data, {});
        const doc = res.data.ids[0];
        return doc;
    } catch (e) {
        console.error('e: ', e);
        return e;
    }
};

export const inspectFile = async (id) => {
    try {
        const res = await axios.get(`${MYCORP_FILE_API_URL}/api/file/inspect?id=${id}`);
        return res.data;
    } catch (e) {
        console.error('e: ', e);
        return e;
    }
};

export const downloadFile = (id) => {
    if (!id) return '';
    return `${MYCORP_FILE_API_URL}/api/file/download?id=${id}`;
};
