/** @format */
import {makeStyles} from '@material-ui/styles';
import styled from 'styled-components';

import {CardContent, Typography, Box} from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    root: {
        height: '42px',
        display: 'inline-block',
        minWidth: '16rem',
        margin: '15px 0px',
        width: '100%',
    },
    cardContent: {
        paddingTop: '0',
    },
}));

export const SortBarWrapper = styled.span`
    height: 42px;
    display: inline-block;
    min-width: 16rem;
    margin: 15px 0px;

    ${(props) => props.theme.breakpoints.down('sm')} {
        margin: 0px 0px 15px 0;
    }
`;

export const MenusWrapper = styled.span`
    padding: 10px 20px;
`;

export const TableFunctions = styled(CardContent)`
    padding: 0;
    margin-bottom: 10px;
`;

export const InnerFunctions = styled.span`
    display: flex;
    alignitems: center;

    span:nth-child(2) {
        padding-left: 10px;
    }

    ${(props) => props.theme.breakpoints.down('sm')} {
        display: block;
        alignitems: center;

        span:nth-child(2) {
            padding-left: 0px;
        }
    }
`;

export const StyledTableItem = styled.div`
    cursor: pointer;
`;

export const Wrapper = styled.div``;
