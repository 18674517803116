/** @format */

import React, {Component} from 'react';
import {myCorpDocApprovalSocket} from 'sockets/DocApproval/DocApproval';

export const DocApprovalContext = React.createContext();

export default class DocApprovalSocketProvider extends Component {
    render() {
        return (
            <DocApprovalContext.Provider
                value={{
                    myCorpDocApprovalSocket,
                }}
            >
                {this.props.children}
            </DocApprovalContext.Provider>
        );
    }
}
