/** @format */

import React from 'react';
import styled from 'styled-components';

import {Typography} from '@material-ui/core';
import {getColor} from 'tools/Utils';

const Status = (props) => {
    const {variant, status} = props;

    return (
        <StyledStatus status={status}>
            <Typography variant={variant || 'subtitle2'}>{status}</Typography>
        </StyledStatus>
    );
};

const StyledStatus = styled.div`
    * {
        color: ${(props) => getColor(props.status, props.theme)};
    }
`;

export default Status;
