/** @format */

export default (palette) => ({
    root: {
        color: palette.icon,
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.03)',
        },
    },
});
