/** @format */

import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import CustomDialog from 'components/Dialog';

import {getLatestVersion} from 'api/System';
import packageJson from '../../../package.json';
import {isLocalhost, unregister} from '../../serviceWorker';

const VersionCheck = (props) => {
    const [reload, setReload] = useState(false);
    const [version, setVersion] = useState('');

    const getVersion = async () => {
        try {
            const {version} = await getLatestVersion();
            setVersion(version);
            console.log('version:  ', {
                latestVersion: version,
                currentVersion: packageJson.version,
            });

            if (version > packageJson.version) setReload(true);
        } catch (e) {
            console.log('e: ', e);
        }
    };

    useEffect(() => {
        if (!isLocalhost) getVersion();
    }, []);

    if (!reload) return null;
    return (
        <CustomDialog
            title={`MyCorp latest version is ${version}, your current is outdated. Please update.`}
            open={true}
            onNegative={() => {}}
            onPositive={() => {
                unregister(() => {
                    window.location.reload();
                });
            }}
        />
    );
};

VersionCheck.propTypes = {
    className: PropTypes.string,
};

export default VersionCheck;
