/** @format */

import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import useStyles from 'theme/styles';

const Loader = (props) => {
    const classes = useStyles();
    return (
        <Backdrop className={classes.backdrop} open={props.loading}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};

Loader.propTypes = {};

export default Loader;
