/** @format */

import {useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import {getUserStatus} from 'api/Auth';
import {AuthContext} from 'contexts/Auth';

const UserCheck = (props) => {
    const {forceSignOut, getUserInfo} = useContext(AuthContext);

    const checkUserStatus = async () => {
        try {
            const userInfo = getUserInfo();
            if (!userInfo || !userInfo.email) return;
            const user = await getUserStatus(userInfo.email);
            if (!user || user.status === 'inactive' || user.sessionId != userInfo.sessionId) {
                forceSignOut(userInfo.email, userInfo.system, userInfo.account);
            }
        } catch (e) {
            console.log('e: ', e);
        }
    };

    useEffect(() => {
        checkUserStatus();
    }, []);

    return null;
};

UserCheck.propTypes = {
    className: PropTypes.string,
};

export default UserCheck;
