/** @format */

import React, {useEffect, useContext, useState, createContext} from 'react';
import {useHistory} from 'react-router-dom';

import {Alert} from 'components';
import {LockModeAlert} from 'views/Settings/LockMode';

import {SidebarContext} from 'layouts/Member/Member';
import {AuthContext} from 'contexts/Auth';

import {AuthContext as AuthSocket} from 'sockets';

export const LockModeContext = createContext({});

export const LockModeProvider = (props) => {
    const {locked, path, children} = props;

    const [lockModeVerified, setLockModeVerified] = useState(false);
    const {getUserInfo} = useContext(AuthContext);
    const {lockMode} = getUserInfo();

    useEffect(() => {
        setLockModeVerified(false);
    }, [lockMode]);

    return (
        <LockModeContext.Provider
            value={{
                lockMode,
                lockModeVerified,
                setLockModeVerified,
            }}
        >
            {lockMode ? (
                <>
                    {locked && !lockModeVerified ? <LockModeVerification onVerify={setLockModeVerified} /> : null}
                    {locked && !lockModeVerified ? null : <span key={path}>{children}</span>}
                </>
            ) : (
                <span key={path}>{children}</span>
            )}
        </LockModeContext.Provider>
    );
};

const LockModeVerification = (props) => {
    const {onVerify} = props;

    const [open, setOpen] = useState(false);
    const [pin, setPin] = useState('');
    const [alertState, setAlertState] = useState({
        show: false,
        title: '',
        remarks: '',
    });

    const history = useHistory();
    const {setShowMenu} = useContext(SidebarContext);
    const {myCorpAuthSocket} = useContext(AuthSocket);
    const {getUserInfo} = useContext(AuthContext);
    const userInfo = getUserInfo();

    const verifyPin = async (pin) => {
        try {
            const {email} = await myCorpAuthSocket.verifyPin(userInfo.email, userInfo.account, userInfo.system, pin);
            if (email === userInfo.email) {
                setOpen(false);
                onVerify && onVerify(true);
                return;
            }

            throw email;
        } catch (e) {
            onVerify && onVerify(false);
            setAlertState({
                show: true,
                title: 'Fail to verify pin',
                remarks: 'You have provided invalid pin.',
            });
        }
    };

    useEffect(() => {
        setShowMenu && setShowMenu(false);
        setOpen(true);
    }, []);

    return (
        <>
            <Alert
                open={alertState.show}
                title={alertState.title}
                remarks={alertState.remarks}
                onPositive={() => setAlertState({show: false})}
            />
            <LockModeAlert
                open={open}
                onNegative={() => history.push('/web/dashboard/home')}
                onPositive={{
                    label: 'Submit',
                    disabled: pin.length !== 6,
                    onClick: () => verifyPin(pin),
                }}
                value={pin}
                onChange={(e) => {
                    const {value} = e.currentTarget;
                    if (value.length <= 6) setPin(value);
                }}
                label="Please enter your lock mode pin and click submit"
            />
        </>
    );
};
