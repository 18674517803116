/** @format */

import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import {RouteWithLayout} from 'components';
import {Sockets as SocketsLayout} from 'layouts';

import {
    DocApprovalList,
    ActiveTaskList,
    Settings,
    ChangePassword,
    ChangeLogo,
    ChangeProfile,
    ResetPassword,
    AuthoriseList,
    NotifyList,
    Expenses,
    NotFound,
    ChangePIN,
    About,
} from 'views';

export const BaseRoutes = (props) => {
    const {path} = props.match;
    const web = path.includes('web');

    return (
        <Switch>
            <RouteWithLayout
                exact
                privatePage={web}
                path={`${path}doc-approval/:token/:email/:mode/:type`}
                component={DocApprovalList}
            />
            <RouteWithLayout
                exact
                privatePage={web}
                path={`${path}task-active/:token/:email/:mode/:type`}
                component={ActiveTaskList}
            />
            <RouteWithLayout exact privatePage={web} path={`${path}reset-password/:token`} component={ResetPassword} />
            <RouteWithLayout exact privatePage={web} path={`${path}settings/:token`} component={Settings} />
            <RouteWithLayout
                exact
                privatePage={web}
                path={`${path}change-password/:token`}
                component={ChangePassword}
            />
            <RouteWithLayout exact privatePage={web} path={`${path}change-logo/:token`} component={ChangeLogo} />
            <RouteWithLayout exact privatePage={web} path={`${path}change-profile/:token`} component={ChangeProfile} />
            <RouteWithLayout exact privatePage={web} path={`${path}change-pin/:token`} component={ChangePIN} />
            <RouteWithLayout
                exact
                privatePage={web}
                path={`${path}authorise/:token/:email`}
                component={AuthoriseList}
            />
            <RouteWithLayout exact privatePage={web} path={`${path}notify/:token/:email`} component={NotifyList} />
            <RouteWithLayout exact privatePage label="Expenses" path={`${path}expenses/:token`} component={Expenses} />
            <RouteWithLayout exact privatePage label="About" path={`${path}about/:token`} component={About} />
            <Route component={NotFound} />
        </Switch>
    );
};
