/** @format */

import typography from '../typography';

export default (palette) => ({
    root: {
        ...typography.body1,
        borderBottom: `1px solid ${palette.divider}`,
    },
});
