/** @format */
import React, {useState, useContext, useEffect} from 'react';
import {makeStyles} from '@material-ui/styles';

import {Grid, Typography} from '@material-ui/core';
import {ImagePreview} from 'components';
import {ThemeContext} from 'theme';
import LogoImage from 'assets/icon/mycorp-logo.png';
import LogoImageWhite from 'assets/icon/mycorp-logo-white.png';

import {AuthContext} from 'contexts/Auth';
import {downloadFile} from 'api/DocApproval';
import {version} from '../../../package.json';
import {MODE} from 'tools/Constants';

const Logo = (props) => {
    const {history} = props;

    const classes = useStyles();
    const {getDarkMode} = useContext(ThemeContext);
    const {getAccountInfo} = useContext(AuthContext);
    const {logo_light, logo_dark} = getAccountInfo();

    const defaultLogo = getDarkMode() ? LogoImageWhite : LogoImage;
    const accountLogo = downloadFile(getDarkMode() ? logo_dark || logo_light : logo_light || logo_dark);

    return (
        <div className={classes.wrapper}>
            <ImagePreview
                onClick={() => history && history.push('/')}
                src={!accountLogo ? defaultLogo : accountLogo}
                alt="mycorp-logo"
            />
            <Typography variant="h6">{version}</Typography>
            {MODE === 'DEV' && (
                <Typography display="inline" className={classes.envText}>
                    <b>TEST</b>
                </Typography>
            )}
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    wrapper: {
        textAlign: 'center',
        [theme.breakpoints.down('xl')]: {
            marginBottom: '70px',
        },
        [theme.breakpoints.down('lg')]: {
            marginBottom: '70px',
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: '50px',
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: '20px',
        },
    },
    envText: {
        color: theme.palette.primary.main,
    },
}));

export default Logo;
