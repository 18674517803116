/** @format */

import React from 'react';
import {default as Member} from './Member';

import AuthSocketProvider from 'sockets/Auth/AuthSocketProvider';
import AuthoriseSocketProvider from 'sockets/Authorise/AuthoriseSocketProvider';
import DocApprovalSocketProvider from 'sockets/DocApproval/DocApprovalSocketProvider';
import ExpensesSocketProvider from 'sockets/Expenses/ExpensesSocketProvider';
import AuditSocketProvider from 'sockets/Audit/AuditSocketProvider';
import ContactSocketProvider from 'sockets/Contact/ContactSocketProvider';

export const Sockets = ({children}) => {
    return (
        <AuthSocketProvider>
            <AuthoriseSocketProvider>
                <DocApprovalSocketProvider>
                    <AuditSocketProvider>
                        <ContactSocketProvider>
                            <ExpensesSocketProvider>{children}</ExpensesSocketProvider>
                        </ContactSocketProvider>
                    </AuditSocketProvider>
                </DocApprovalSocketProvider>
            </AuthoriseSocketProvider>
        </AuthSocketProvider>
    );
};

export {Member};
