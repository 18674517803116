/** @format */

import React from 'react';
import styled from 'styled-components';

import {Typography, Grid} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

import LockIcon from '@material-ui/icons/Lock';

const FormField = (props) => {
    const {
        preview,
        description,
        margin,
        name,
        children,
        error,
        inputHeight,
        marginHeight,
        variant = TYPE.FILLED,
    } = props;
    const elem = document.getElementsByClassName(`${name}-description`)[0];
    const clientHeight = elem && elem.clientHeight;

    if (preview) return <PreviewFormField {...props} />;
    return (
        <Wrapper
            margin={margin}
            marginHeight={marginHeight}
            description={description}
            inputHeight={inputHeight}
            descriptionHeight={clientHeight}
        >
            <FormControl fullWidth variant={variant} error={error ? true : false}>
                <Label {...props} />
                {children}
                {error && <FormHelperText id="standard-weight-helper-text">{error}</FormHelperText>}
            </FormControl>
        </Wrapper>
    );
};

const PreviewFormField = (props) => {
    const {children, margin} = props;
    return (
        <Wrapper margin={margin}>
            <Label {...props} />
            <Typography variant="h6">{children}</Typography>
        </Wrapper>
    );
};

export const Label = (props) => {
    const {preview, label, labelVariant, description, mandatory, name} = props;

    if (preview && !labelVariant) {
        return (
            <Typography variant="subtitle2">
                {label} {!mandatory ? null : <Mandatory>*</Mandatory>}
                {!description ? null : (
                    <Typography variant="subtitle1" className={`${name}-description`}>
                        {description}
                    </Typography>
                )}
            </Typography>
        );
    }

    if (labelVariant === 'label2') {
        return (
            <>
                <Typography variant="body1" gutterBottom>
                    <b>{label}</b>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    {description}
                </Typography>
            </>
        );
    }

    if (labelVariant === 'label3') {
        return (
            <>
                <Typography variant="body1">
                    <b>{label}</b>
                </Typography>
                <Typography variant="body1">{description}</Typography>
            </>
        );
    }

    return (
        <InputLabel>
            <Grid container alignItems="center" direction="row">
                {label} {!mandatory ? null : <Mandatory>*</Mandatory>}
                {props.disabled ? (
                    <LockIcon
                        style={{
                            fontSize: 14,
                            margin: '0 10px',
                        }}
                    />
                ) : null}
            </Grid>
            {!description ? null : (
                <Typography variant="subtitle1" className={`${name}-description`}>
                    {description}
                </Typography>
            )}
        </InputLabel>
    );
};

export const TYPE = {
    OUTLINED: 'outlined',
    FILLED: 'filled',
};

const Wrapper = styled.div`
    margin: ${(props) => (props.margin ? '15px 15px 15px 0px' : `${props.marginHeight || '15'}px 0px`)};

    .MuiFilledInput-input {
        padding: ${(props) =>
            props.description
                ? `${props.descriptionHeight + 10}px 12px 10px`
                : `${props.inputHeight || '27'}px 12px 10px`};
    }
`;

const Mandatory = styled.span`
    color: ${(props) => props.theme.palette.error.main};
`;

export default FormField;
