/** @format */

import React, {useState, createContext} from 'react';

import {Alert} from 'components';

export const AlertContext = createContext({});

export const AlertProvider = (props) => {
    const {children} = props;
    const [alertState, setAlertState] = useState({
        show: false,
        title: '',
        remarks: '',
    });

    const closeAlert = () => {
        setAlertState((prevState) => ({
            ...prevState,
            show: false,
        }));
    };

    const myCorpAlert = (payload) => {
        setAlertState((prevState) => ({
            ...prevState,
            ...payload,
            onPositive: () => {
                closeAlert();
                payload.onPositive && payload.onPositive();
            },
            onNegative: null,
        }));
    };

    const myCorpDialog = (payload) => {
        setAlertState((prevState) => ({
            ...prevState,
            ...payload,
            onPositive: () => {
                closeAlert();
                payload.onPositive && payload.onPositive();
            },
            onNegative: () => {
                closeAlert();
                payload.onNegative && payload.onNegative();
            },
        }));
    };

    return (
        <AlertContext.Provider
            value={{
                myCorpAlert,
                closeAlert,
                myCorpDialog,
            }}
        >
            <Alert
                open={alertState.show}
                title={alertState.title}
                remarks={alertState.remarks}
                onPositive={alertState.onPositive}
                onNegative={alertState.onNegative}
            />
            {children}
        </AlertContext.Provider>
    );
};
