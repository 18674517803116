/** @format */

export default (palette, darkMode) => ({
    contained: {
        boxShadow: '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
        backgroundColor: palette.primary.main,
        color: palette.primary.contrastText,

        textTransform: 'none',
        height: 44,
        elevation: 3,
        shadowOffset: {width: 0, height: 2},
        shadowOpacity: 0.3,

        '&$disabled': {
            backgroundColor: darkMode ? '#5b5b5c' : '#e6e6e6',
            color: darkMode ? '#929393' : '#c1c1c1',
        },
    },
    outlined: {
        textTransform: 'none',
        height: 44,
    },
});
