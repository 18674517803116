/** @format */

import React, {useContext, useState, useEffect} from 'react';

import {Button, ButtonTypes} from 'components';
import {Grid, Typography, IconButton} from '@material-ui/core';
import {TabsWrapper, Divider, FunctionalButton} from './TabsButton.style';

import {SidebarContext} from 'layouts/Member/Member';
import {useKeyPress} from 'tools/CustomHooks';

const TabsButton = (props) => {
    const {functionalButtons = [], tabsButtons = []} = props;
    const {showMenu, isSmall} = useContext(SidebarContext);

    return (
        <TabsWrapper length={functionalButtons.length} show={isSmall ? !showMenu : true}>
            {functionalButtons.length === 0 ? null : (
                <div className="container container-functional">
                    {functionalButtons.map((x, i) => {
                        const {...rest} = x;

                        return (
                            <span key={i}>
                                {functionalButtons < 2 ? null : <Divider />}
                                <FunctionalButtonHOC
                                    index={i}
                                    length={functionalButtons.length}
                                    color="primary"
                                    className="column functional-buttons"
                                    {...rest}
                                />
                            </span>
                        );
                    })}
                </div>
            )}
            {tabsButtons.length === 0 ? null : (
                <div className="container container-tabs">
                    <Grid container direction="row" justify="center" alignItems="center">
                        {tabsButtons.map((x, i) => {
                            const {onClick, disabled, icon: Icon, label} = x;
                            return (
                                <Grid key={i} item className="column" xs={12 / tabsButtons.length}>
                                    <Button
                                        fullWidth
                                        className="tab-button"
                                        variant={ButtonTypes.OUTLINED}
                                        onClick={onClick}
                                        disabled={disabled}
                                    >
                                        {Icon && <Icon />}
                                        {label && <Typography variant="h6">{label}</Typography>}
                                    </Button>
                                </Grid>
                            );
                        })}
                    </Grid>
                </div>
            )}
        </TabsWrapper>
    );
};

const FunctionalButtonHOC = (props) => {
    const {onKeyPress, icon: Icon, onClick, ...rest} = props;
    const onPress = useKeyPress(onKeyPress || '');

    useEffect(() => {
        if (onPress) onClick();
    }, [onPress]);

    return (
        <FunctionalButton className="column functional-buttons" onClick={onClick} color="primary" {...rest}>
            {Icon && <Icon />}
        </FunctionalButton>
    );
};

export default TabsButton;
