/** @format */

import React, {useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import {myCorpNotifySocketSocket} from 'sockets/Notify/Notify';
import {AuthContext} from 'contexts/Auth';
import {Snackbar} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const NotifyCheck = (props) => {
    const {getUserInfo} = useContext(AuthContext);

    const [state, setState] = React.useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
    });

    const close = () => {
        const newState = Object.assign({}, state);
        newState.message = '';
        newState.open = false;
        setState(newState);
    };

    const checkNotify = async () => {
        try {
            const userInfo = getUserInfo();
            await myCorpNotifySocketSocket.init(userInfo.token, userInfo.account, userInfo.system, onSocketConnect);
        } catch (e) {
            console.log('e: ', e);
        }
    };

    const onSocketConnect = (e) => {
        const callback = async (notifyToRender) => {
            const newState = Object.assign({}, state);
            newState.message = notifyToRender;
            newState.open = true;
            setState(newState);
        };
        myCorpNotifySocketSocket.subscribeNotify(callback);
    };

    useEffect(() => {
        checkNotify();
    }, []);

    const {vertical, horizontal, open, message} = state;

    return (
        <Snackbar
            anchorOrigin={{vertical, horizontal}}
            autoHideDuration={6000}
            open={open}
            onClose={close}
            onClick={(e) => {
                const title = message;
                const url =
                    title.indexOf('Notification') >= 0
                        ? '/web/notify'
                        : title.indexOf('Authentication') >= 0
                        ? '/web/authenticate'
                        : title.indexOf('Task') >= 0
                        ? '/web/instatask'
                        : title.indexOf('Approval') >= 0
                        ? '/web/instaapv'
                        : '/';
                props.history.push(url);
            }}
            key={vertical + horizontal}
        >
            <Alert onClose={close} severity="success">
                {message}
            </Alert>
        </Snackbar>
    );
};

NotifyCheck.propTypes = {
    className: PropTypes.string,
};

export default NotifyCheck;
