/** @format */

import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Input, FormField} from 'components';

const DialogComponent = (props) => {
    const {
        open,
        onClose,
        onPositive,
        onNegative,
        title,
        remarks,
        lateReason,
        onLateReasonChange,
        dueDate,
        onDueDateChange,
        reAcceptError,
    } = props;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                {reAcceptError && (
                    <DialogContentText
                        id="alert-dialog-error"
                        style={{
                            color: 'red',
                        }}
                    >
                        {reAcceptError}
                    </DialogContentText>
                )}
                <DialogContentText id="alert-dialog-description">{remarks}</DialogContentText>
                <FormField label="Late reason">
                    <Input fullWidth name="lateReason" value={lateReason} onChange={onLateReasonChange} />
                </FormField>
                <FormField label="Revised due date">
                    <Input fullWidth type="date" name="dueDate" value={dueDate} onChange={onDueDateChange} />
                </FormField>
            </DialogContent>
            <DialogActions>
                <Button onClick={onPositive} color="primary" autoFocus>
                    OK
                </Button>
                <Button onClick={onNegative} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogComponent;
