/** @format */

import React, {useMemo, useState, createContext} from 'react';
import {ThemeProvider as SThemeProvider} from 'styled-components';
import {createMuiTheme} from '@material-ui/core';

import paletteLight from 'theme/palette.light.js';
import paletteDark from 'theme/palette.dark.js';

import typography from 'theme/typography';
import overrides from 'theme/overrides';

import {ThemeProvider as MThemeProvider} from '@material-ui/styles';
import {StylesProvider} from '@material-ui/core/styles';

export const ThemeContext = createContext({});

const getDarkMode = () => {
    const darkMode = localStorage.getItem('@mycorp_darkmode');
    return darkMode === 'true' ? true : false;
};

export const ThemeProvider = (props) => {
    const {children} = props;
    const [dark, setDark] = useState(getDarkMode());

    const setDarkMode = (darkmode) => {
        setDark(darkmode);
        localStorage.setItem('@mycorp_darkmode', darkmode);
    };

    const palette = getDarkMode() ? paletteDark : paletteLight;
    const theme = useMemo(
        () =>
            createMuiTheme({
                palette,
                typography: typography(palette),
                overrides: overrides(palette, dark),
                zIndex: {
                    appBar: 1200,
                    drawer: 1100,
                },
            }),
        [palette, dark]
    );

    return (
        <StylesProvider injectFirst>
            <MThemeProvider theme={theme}>
                <SThemeProvider theme={theme}>
                    <ThemeContext.Provider
                        value={{
                            setDarkMode,
                            getDarkMode,
                            theme,
                        }}
                    >
                        {children}
                    </ThemeContext.Provider>
                </SThemeProvider>
            </MThemeProvider>
        </StylesProvider>
    );
};
