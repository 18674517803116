/** @format */

import React from 'react';

import {makeStyles} from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import {Drawer as MDrawer, CardContent, Grid} from '@material-ui/core';

const Drawer = (props) => {
    const {onClose, children} = props;
    const classes = useStyles();

    return (
        <MDrawer {...props}>
            <CardContent>
                <Grid container direction="row" justify="flex-end">
                    <Grid item>
                        <IconButton color="primary" aria-label="close" onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid container direction="row">
                    <Grid item xs={12} md={12} lg={12}>
                        {children}
                    </Grid>
                </Grid>
            </CardContent>
        </MDrawer>
    );
};

export const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.background.secondary,
        width: '100%',
        height: '100%',
    },
}));

export default Drawer;
