/** @format */

import socketIOClient from 'socket.io-client';
const uuidv1 = require('uuid/v1');

const {MYCORP_EXPENSES_ENDPOINTS} = require('tools/Constants');

class MyCorpExpensesSocket {
    _socket = null;

    init = (token, account, system, module, onConnect) => {
        this._socket = socketIOClient(MYCORP_EXPENSES_ENDPOINTS, {
            path: '/expenses',
            rememberTransport: false,
            transports: ['websocket'],
            query: {
                token,
                account,
                system,
                module,
            },
        });

        this._socket.on('connect', onConnect);
    };

    subscribeExpenses = (callback) => {
        if (!callback) return;
        this._socket.on('expenses', callback);
    };

    updateExpensesListener = (filter) => {
        this._socket.emit('updateExpensesListener', filter);
    };

    submitNewExpense = (payload) => {
        const correlationId = uuidv1();

        return new Promise(async (resolve, reject) => {
            this._socket.emit('submitNewExpense', {correlationId, ...payload});
            this._socket.on(correlationId, (response) => {
                const {ok, result, payload} = response || {};
                if (ok == 1) resolve(payload);
                else reject(response);
            });
        });
    };

    updateExpense = (payload) => {
        const correlationId = uuidv1();

        return new Promise(async (resolve, reject) => {
            this._socket.emit('updateExpense', {correlationId, ...payload});
            this._socket.on(correlationId, (response) => {
                const {ok, result, payload} = response || {};
                if (ok == 1) resolve(payload);
                else reject(response);
            });
        });
    };

    getExpenseCategories = () => {
        const correlationId = uuidv1();

        return new Promise(async (resolve, reject) => {
            this._socket.emit('getExpenseCategories', {correlationId});
            this._socket.on(correlationId, (response) => {
                const {ok, result} = response || {};
                if (ok == 1) resolve(result);
                else reject(response);
            });
        });
    };

    getAttachments = (docId) => {
        const correlationId = uuidv1();

        return new Promise(async (resolve, reject) => {
            this._socket.emit('getAttachments', {docId, correlationId});
            this._socket.on(correlationId, (response) => {
                const {ok, result} = response || {};
                if (ok == 1) resolve(result);
                else reject(response);
            });
        });
    };

    getPaymentyModes = () => {
        const correlationId = uuidv1();

        return new Promise(async (resolve, reject) => {
            this._socket.emit('getPaymentyModes', {correlationId});
            this._socket.on(correlationId, (response) => {
                const {ok, result} = response || {};
                if (ok == 1) resolve(result);
                else reject(response);
            });
        });
    };

    getProjects = () => {
        const correlationId = uuidv1();

        return new Promise(async (resolve, reject) => {
            this._socket.emit('getProjects', {correlationId});
            this._socket.on(correlationId, (response) => {
                const {ok, result} = response || {};
                if (ok == 1) resolve(result);
                else reject(response);
            });
        });
    };
}

export const myCorpExpensesSocket = new MyCorpExpensesSocket();
