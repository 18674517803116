/** @format */

import React from 'react';

import styled from 'styled-components';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MCheckbox from '@material-ui/core/Checkbox';

const Checkbox = (props) => {
    const {label, value} = props;

    return (
        <Wrapper>
            <FormGroup row>
                <FormControlLabel
                    {...props}
                    control={<MCheckbox color="primary" checked={value} {...props} />}
                    label={label}
                />
            </FormGroup>
        </Wrapper>
    );
};

const Wrapper = styled.span`
    .MuiTypography-body1 {
        margin: 0 10px;
    }
`;

export default Checkbox;
